import { render, staticRenderFns } from "./Trips.vue?vue&type=template&id=5491d777&scoped=true&"
import script from "./Trips.vue?vue&type=script&lang=js&"
export * from "./Trips.vue?vue&type=script&lang=js&"
import style0 from "./Trips.vue?vue&type=style&index=0&id=5491d777&prod&lang=css&"
import style1 from "./Trips.vue?vue&type=style&index=1&id=5491d777&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5491d777",
  null
  
)

export default component.exports