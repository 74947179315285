
If this is in mobile (overflow) mode:

Container for the *non-auto-scrolling* Trips list to be displayed

Handles the API call to get the trips data based on the querystring data passed in as a prop,
and then iterates over the results to display the individual TripDisplay components

----------------------------------------------

If this is in non-mobile mode:

display the ScrollingTripsContainer, which will handle its own functionality

<template>
    <div ref="tripMain" class="trips-container">
        <!-- 3rem -->
        <div 
            ref="tripsScroller"
            v-if="$props.overflow && !$props.query.scrolling"
            :class="{
                'center-flow overflow scroll-check': $props.overflow,
                'center-flow no-overflow': !$props.overflow || $props.query.scrolling
            }"
            :style="{width: '100vw', height: `calc(${mainHeight - ($isTabletForm ? 10 : 9) - (($isTabletForm && $props.query.footer !== false) ? 7 : 0) + ($props.query.scrolling ? -1 : 0) - ($isRotatedMobile ? 3 : 0)} * var(--mvh))`, top: `${$isRotatedMobile ? 'calc(10 * var(--mvh))' : ''}`, position: `${$isRotatedMobile ? 'absolute' : ''}`}"
        >
                <div v-if="$isDisconnected"
                    class="disconnected-message-nonscroll"
                >
                    <div class="message-bell">
                        <i class="fas fa-bell"></i>
                    </div>
                    Data connection temporarily lost, scheduled information only. Please visit www.njtransit.com for updates.
                </div>
                <MobileMessage 
                    v-if="false && message && ($props.query.messages || !($props.query.hidemodeswitch || $isSignMode))" 
                    :message="message" 
                    :emergency="isEmergency" 
                    :expandByDefault="$props.query.messages"
                />

                <!-- 
                    v-if="message && message.length && ($props.query.messages || !($props.query.hidemodeswitch || $isSignMode))"
                -->
                <Expansion
                    v-for="(m, index) in message"
                    v-bind:key="index"
                    :index="index"
                    :message="m.message" 
                    :emergency="isEmergency" 
                    :expandByDefault="$props.query.messages"
                    :show="($props.query.messages || !($props.query.hidemodeswitch || $isSignMode))"
                    :isFirst="index === 0"
                    :isLast="index === message.length-1"
                />

                <div class="alert_filter_container" :style="(message.length && message.length > 0) ? '' : 'margin-top: 0.75rem;'">
                    <div class="station-alert-container" v-if="pageType === 'rail' && (location || locationName)">
                        <div 
                            :class="'station-alert-button ' + (alertAlreadyActive ? 'active' : ('base' + (alertProcessing ? ' processing' : '')))" 
                            @click="toggleAlert"
                            :style="(($isMobile && alertAlreadyActive) ? 'padding-top: 0.2rem; padding-bottom: 0.2rem; display: flex; align-items: center; gap: 0.15rem;' : '')"
                        >
                            <i class="fas fa-bell"></i>&nbsp;
                            <div style="width: 0.3rem;"></div>
                            <div v-if="alertAlreadyActive">
                                <div v-if="$isMobile" style="display: flex; flex-direction: column;">
                                    <span style="font-size: 0.75rem;">STATION ALERTS:</span>
                                    <span style="font-weight: bold; font-size: 1rem; letter-spacing: 1px;">UNSUBSCRIBE</span>
                                </div>
                                <span v-if="!$isMobile">STATION ALERTS:&nbsp;</span><span v-if="!$isMobile" style="font-weight: bold; letter-spacing: 1.2px; font-size: 1.2rem;">UNSUBSCRIBE</span> 
                            </div>
                            <div v-else>
                                GET STATION ALERTS
                            </div>
                        </div>
                    </div>

                    <TripsFilterPanel v-if="!$props.query.messages" :routes="filterList" :query="$props.query" :pageType="pageType" />
                </div>

                <div v-if="!$isTabletForm" class="spacer"> </div>

                <div class="no-trips-message" v-if="!initialLoadComplete && pageType === 'bus'">
                    <Loader />
                </div>

                <div v-if="pageType === 'bus' && !$isMobile && trips.length > 0" style="display: flex; flex-direction: column; align-items: center; transform: scale(0.8) translateY(calc(-10% - 1rem)); width: 100vw; height: calc(82%);">
                    <TripDisplay
                        v-bind:key="trip.internal_trip_number + '_' + index + '_' + Math.random()"
                        v-for="(trip, index) in trips" 
                        v-bind:trip="trip" 
                        v-bind:dataIndex="index"
                        v-bind:query="$props.query"
                        :isRail="isRail"
                        @navigate-to-stops="navigateToStops"
                        @open-expanded-trip-card="openExpandedTripCard"
                    />
                </div>

                <TripDisplay
                    v-else
                        v-bind:key="trip.internal_trip_number + '_' + index + '_' + Math.random()"
                        v-for="(trip, index) in trips" 
                        v-bind:trip="trip" 
                        v-bind:dataIndex="index"
                        v-bind:query="$props.query"
                        :isRail="isRail"
                        @navigate-to-stops="navigateToStops"
                        @open-expanded-trip-card="openExpandedTripCard"
                    />


                <div v-if="isEmergency" class="emergency_message">
                    <MessageDisplay :key="'message_top'" :message="message" />
                </div>

                <div class="no-trips-message" v-if="initialLoadComplete && trips.length === 0 && !isEmergency">
                    See Schedule for Departures
                </div>

                <div class="no-trips-message" v-if="!initialLoadComplete && pageType !== 'bus'">
                    <Loader />
                </div>

                <div v-if="isExpandedTrip" class="expanded-card-backdrop"></div>

                <div v-if="isExpandedTrip" 
                    :class="'expanded-railcar-card ' 
                            + (($isTabletForm && !$isRotatedMobile) ? 'card-smallscreen' : '') 
                            + (($isTabletForm && !$isRotatedMobile && !$isMobile) ? ' card-tablet': '')
                            + (($isRotatedMobile) ? ' rotated-mobile' : '')
                            + (($isBorderlineLaptop) ? ' borderline-laptop': '')
                            + (($isMidTablet) ? ' mid-tablet': '')
                            + (($isBorderlineTablet) ? ' borderline-tablet': '')
                            + (isIOS ? ' ios' : ' not-ios')"
                >
                    <div class="expanded-close-button" @click="closeExpandedCard">
                        <i class="fas fa-times" />
                    </div>

                    <TripDisplay
                        v-if="$isTabletForm && !$isRotatedMobile"
                        v-bind:trip="expandedTrip" 
                        v-bind:dataIndex="expandedIndex"
                        v-bind:query="$props.query"
                        v-bind:mobileCard="$isTabletForm && !$isRotatedMobile"
                        v-bind:tabletCard="$isTabletForm && !$isMobile && !$isRotatedMobile"
                        v-bind:rotatedMobile="$isRotatedMobile"
                        :isRail="isRail"
                        mode="expandedCard"
                        @navigate-to-stops="navigateToStops" />

                    <div v-else class="laptop-expanded-trip-display-container">
                        <TripDisplay
                            v-bind:trip="expandedTrip" 
                            v-bind:dataIndex="expandedIndex"
                            v-bind:query="$props.query"
                            v-bind:mobileCard="$isTabletForm && !$isRotatedMobile"
                            v-bind:rotatedMobile="$isRotatedMobile"
                            :isRail="isRail"
                            mode="expandedCard"
                            @navigate-to-stops="navigateToStops" />
                    </div>

                    <div class="expanded-details">
                        <div v-if="!$isTabletForm || $isRotatedMobile" class="laptop-main-expanded-content">
                            <div class="laptop-expanded-colors">
                                <div class="color-key">
                                    <div class="color-code color-code-green"></div>
                                    <span>Light</span>
                                </div>
                                <div class="color-key">
                                    <div class="color-code color-code-yellow"></div>
                                    <span>Medium</span>
                                </div>
                                <div class="color-key">
                                    <div class="color-code color-code-red"></div>
                                    <span>Heavy</span>
                                </div>
                                <div class="color-key">
                                    <div class="color-code color-code-gray"></div>
                                    <span>No Data</span>
                                </div>
                            </div>

                            <div style="display: flex; justify-content: center;">
                                <div style="display: flex; flex-direction: column; /*flex-basis: 0;*/">
                                    <div class="cars">
                                        <div class="car-label">Car&nbsp;#</div>
                                        <div
                                            v-for="(item, index) in expandedCars"
                                            :class="`expanded-car ${item.firstInCar ? 'first-in-car' : ''} ${(index+1 === expandedTrip.passload.length) ? ' first-car-of-train' : ''}`" 
                                            :key="index"
                                        >
                                            <div :class="'expanded-car-element' + `${(item.color === '#0B6623') ? ' dark' : ''}` + `${item.color === '#FFD300' ? ' yellow-car': ''}`" :style="`background-color: ${item.color};`">
                                                <div :class="`${item.rest ? 'expanded-rest' : ''}`"></div>
                                            </div>
                                            <div class="expanded-car-id">{{ item.num }}</div>
                                        </div>
                                        <div class="laptop-expanded-destination">
                                            <!-- <div>towards</div> -->
                                            <ExpandedArrowSVGIcon rotate="true" />
                                            <div class="expanded-destination">
                                                {{ expandedTrip.header.trim() }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="yellow-line"></div>
                                    <div class="yellow-line-message">
                                        Please Stand Back from the Yellow Line
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="$isTabletForm && !$isRotatedMobile" class="mobile-cars-line-container">
                            <div v-if="$isTabletForm && !$isRotatedMobile" class="cars">
                                <div class="car-label">Car&nbsp;#</div>
                                <div
                                    v-for="(item, index) in expandedCars"
                                    :class="`expanded-car ${item.firstInCar ? 'first-in-car' : ''}`" 
                                    :key="index"
                                >
                                    <div class="expanded-car-id">{{ item.num }}</div>
                                    <div class="expanded-car-element" :style="`background-color: ${item.color};`"></div>
                                    <div :class="`${item.rest ? 'expanded-rest' : ''}`"></div>
                                </div>
                            </div>
                            
                            <div v-if="$isTabletForm && !$isRotatedMobile" style="display: grid; grid-template-columns: 1fr 3fr;">
                                <div class="yellow-line"></div>
                                <div class="yellow-line-message">
                                    Please Stand Back from the Yellow Line
                                </div>
                            </div>
                        </div>
                    
                        <div v-if="$isTabletForm && !$isRotatedMobile" class="expanded-content">
                            <!-- <div>towards</div> -->
                            <div class="expanded-destination">
                                {{ expandedTrip.header.trim() }}
                            </div>
                            <ExpandedArrowSVGIcon :mobile="this.$isMobile" />
                            <div class="color-key">
                                <div class="color-code color-code-green"></div>
                                <span>Light</span>
                                <div class="color-code color-code-yellow"></div>
                                <span>Medium</span>
                                <div class="color-code color-code-red"></div>
                                <span>Heavy</span>
                                <div class="color-code color-code-gray"></div>
                                <span>No Data</span>
                            </div>
                            <div class="expanded-message">
                                Quiet cars are available on the first and last cars of eastbound trains between 6:00 a.m. and 10:00 a.m. and westbound trains between 4:00 a.m. and 8:00 p.m.
                            </div>
                        </div>
                        <div v-else class="laptop-expanded-content">
                            <div class="expanded-message">
                                Quiet cars are available on the first and last cars of eastbound trains between 6:00 a.m. and 10:00 a.m. and westbound trains between 4:00 a.m. and 8:00 p.m.
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        <div 
            v-else
            :class="{
                'center-flow overflow scroll-check': $props.overflow,
                'center-flow no-overflow': !$props.overflow || $props.query.scrolling
            }"
            :style="{width: '100vw', height: `calc(${mainHeight - ($props.query.qr ? $QR_HEIGHT : 0) - ($isTabletForm ? 10 : 9) - (($isTabletForm && $props.query.footer !== false) ? 7 : 0) + ($props.query.scrolling ? -1 : 0) - ($isRotatedMobile ? 3 : 0)} * var(--mvh))`}"
        >
            <ScrollingTripsContainer 
                    :query="$props.query" 
                    :isRail="isRail"
                    @navigate-to-stops="navigateToStops" 
                />
        </div>

        <QRContainer v-if="$props.query.qr" :url="cleanURL"/>
    </div>
</template>

<script>
    import {blockScrollUp} from "@/Utilities";

    import TripDisplay from "@/data_display/TripDisplay";
    import ScrollingTripsContainer from "@/components/ScrollingTripsContainer";
    import MessageDisplay from "@/data_display/MessageDisplay";

    import TripsFilterPanel from "@/components/TripsFilterPanel";
    import Loader from "@/components/Loader";

    import Expansion from "./Expansion";

    import QRContainer from "@/components/QRContainer";

    import { insertPlane } from "@/Utilities";

    import SubscriptionHandler from "@/SubscriptionHandler";

    // import { Fragment } from "vue-fragment";
    // import { getTrips as getTrips } from "@/requests/DataRequests";
    import { getTripsNEW as getTrips } from "@/requests/DataRequests";
    import MobileMessage from './MobileMessage.vue';

    import ExpandedArrowSVGIcon from "./ExpandedArrowSVGIcon";

    const dataRequestInterval = 20000;

    let interval = null;

    export default {
        name: "TripsContainer",
        props: ["locationName", "query", "overflow", "mainDisplayHeightReduction", "location"],
        components: {
            Expansion,
            TripDisplay,
            ScrollingTripsContainer,
            // Fragment,
            MobileMessage,
            MessageDisplay,
            TripsFilterPanel,
            Loader,
            ExpandedArrowSVGIcon,
            QRContainer
        },
        watch: {
            $isDisconnected(oldVal, newVal){
                console.log("TRIPS IS DISCONNECTED CHANGED", oldVal, newVal);
                this.disconnectedMessage = newVal;
            },
            isIOS(newval, oldval){
                console.log("IS THIS IOS?", newval, oldval);
            },
            cleanURL(newval, oldval){
                console.log("cleanURL", newval, oldval);
            },
            message(newVal, oldVal){
                console.log("message change", newVal, oldVal);
            },
            alertAlreadyActive(){
                console.log("ALERT ALREADY ACTIVE SET");
            },
            alertProcessing(){
                console.log("ALERT PROCESSING");
            }
        },
        data() {
            return {
                trips: [],
                message: "",
                isEmergency: false,
                // mainHeight: 90,
                // mainHeight: 97,
                mainHeight: 100,
                isRail: false,
                initialLoadComplete: false,
                filterList: [],
                pageType: "",
                lightrailType: "",
                disconnectedMessage: null,
                isExpandedTrip: false,
                expandedTrip: null,
                expandedCars: null,
                expandedIndex: null,
                isIOS: false,
                cleanURL: "",
                alertAlreadyActive: false,
                stationSub: null,
                stationAlertTopic: null,
                alertProcessing: false
            }
        },
        created() {
            console.log("TRIPS CONTAINER CREATED");
            console.log("TRIPS CONTAINER PROPS", this.$props);

            if(window.navigator && (/iPad|iPhone|iPod/.test(navigator.userAgent))){
                this.isIOS = true;
            }

            this.setCleanURL();

            setTimeout(() => {
                if(this.$props.mainDisplayHeightReduction){
                    this.mainHeight -= this.$props.mainDisplayHeightReduction;
                }
    
                if(this.$isSignMode || this.$isTabletForm) {
                    // this.mainHeight += 10;
                    // this.mainHeight += 3;
                }
            }, 0);

            console.log("MAIN HEIGHT SIGN", this.mainHeight, this.$isSignMode);

            // deal with the RAIL data
            const pageType = this.$route.path.split("/")[1];
            this.pageType = pageType;

            if(pageType === "rail"){
                console.log("RAIL MODE HERE?");
                this.$props.query.mode = "RAIL";
                this.isRail = true;
            }
            else if(pageType === "bus"){
                this.$props.query.mode = "BUS";
            }

            // only get the data here if we're in overflow (mobile) mode
            // if(this.$props.overflow){
            // }

            const existingSub = SubscriptionHandler.checkIfAlertIsSet(null, null, this.$props.locationName, this.$props.location);

            if(existingSub){
                this.alertAlreadyActive = true;
                this.stationSub = existingSub.arn;
                this.stationAlertTopic = existingSub.topic;
            }

            if(!this.$props.overflow || this.$props.query.scrolling){
                console.log("DON'T RUN GETDATA FOR MAIN CONTAINER ON SCROLLING");
                return;
            }
                const getData = () => {

                    // skip this if we're currently looking at an expanded card
                    if(this.isExpandedTrip) {
                        return;
                    }

                    getTrips(this.$props.query)
                    .then((res) => {
                        if(!res || !res.data){
                            // just exit if we don't have a proper response
                            console.log("IMPROPER RESPONSE?", res);
                            return;
                        }

                        const data = res.data;

                        this.initialLoadComplete = true;

                        let n = true;
                        if(n){
                            console.log("RETURNING");
                            // return;
                        }

                        console.log("GET SOME TRIPS", data);

                        let tripsToUse = data.DVTrip;

                        tripsToUse = this.filterIncomplete(tripsToUse); 

                        if(this.$props.query.rows){
                            let messageCount = (data.message && data.message.message) ? data.message.message.length : 0; 
                            if(!Array.isArray(data.message)){
                                if(data.message){
                                    messageCount = 1;
                                }
                                else{
                                    messageCount = 0;
                                }
                            }

                            let entries = +this.$props.query.rows - messageCount;
                            if(entries < 1){
                                entries = 1;
                            }
                            tripsToUse = tripsToUse.slice(0, entries);
                        }
                        if(tripsToUse && tripsToUse.length > 3){
                            // tripsToUse = tripsToUse.slice(0, 3);
                        }
                        this.trips = tripsToUse;
                        
                        const m  = data.message ? data.message.message : "";
                        this.setMessages(m);

                        // if(data.message && data.message.isEmergency){
                        //     this.isEmergency = data.message.isEmergency;
                        // }

                        // if(this.message && !Array.isArray(this.message)){
                        //     this.message = [{message: this.message}];
                        // }

                        // if(!this.message){
                        //     this.message = [];
                        // }

                        // this.message.push(this.message[0]);
                        // this.message.push(this.message[0]);

                        console.log("MESSAGE NOT AN ARRAY?", this.message);

                        // if we're not in messages or sign mode, don't show the 
                        // Thank you message if it's the only one
                        if(!this.$props.query.messages 
                            && !this.$isSignMode
                            && this.message.length === 1
                            && this.message[0].message.indexOf("Thank you for riding") === 0)
                        {
                            // just don't do this anymore
                            // this.message = [];
                        }

                        /* FAKE VALUES FOR DISPLAY TESTING
                        this.trips = [{departuretime: ""}];
                        this.message = {message: "awegreg re eh ah a ab afb afb snsgndsna bfa fna fna fn"};
                        */

                        this.createFilterList();

                        console.log("DO WE REACH THIS POINT HERE?", tripsToUse);
                        
                        if(this.$props.query.late 
                            && this.$props.query.filtered
                        ){
                            this.extraLateTrainFilter();
                        }

                        if(this.$props.query.filtered){
                            console.log("FILTERED", this.$props.query);
                        }
                        console.log("RELOAD COMPLETED?", this.trips);
                    });
                }

                getData();
                interval = setInterval(() => {
                    console.log("RELOADING THE TRIP DATA");
                    getData();
                }, dataRequestInterval);

            // setTimeout(() => {
            //     const main = this.$refs.tripMain;
            //     console.log("TRIP MAIN ELEMENT", main);

            //     const val = document.createElement("div");
            //     val.classList.add("size-marker");

            //     const sizeDef = main.style.height;
            //     const sizeVal = main.offsetHeight;
            //     const sizeRect = main.getBoundingClientRect().height;

            //     const full = window.innerHeight;

            //     val.innerText = `H: ${sizeDef}:: ${sizeVal}:: ${sizeRect} // ${full}`;

            //     main.appendChild(val);
            // }, 300);
        },
        mounted() {
            const element = this.$refs.tripsScroller;
            const elementP = this.$refs.tripMain;
            console.log("DO WE HAVE AN ELEMENT?", element, elementP);
            // blockScrollUp(element, 10, "TRIPS SCROLLER");
            blockScrollUp(elementP, 10, "TRIPS MAIN");
        },
        destroyed(){
            if(interval){
                clearInterval(interval);
            }
        },
        methods: {
            setCleanURL(){
                let path = location.href.split("?")[0];
                this.cleanURL = `${path}?stop=${this.$props.query.stop}`;
            },
            navigateToStops(tripStr){
                this.$emit("navigate-to-stops", tripStr);
            },
            openExpandedTripCard(trip, index, closeFunc) {
                console.log("EXPANDED TRIP", trip);

                let tripCopy = null; 
                
                if(trip){
                    tripCopy = {...trip};
                    tripCopy.header = insertPlane(trip.header);
                }

                this.closeFunc = closeFunc;
                this.isExpandedTrip = true;
                this.expandedTrip = tripCopy;
                this.expandedIndex = index;
                const reverse_copy = [...trip.passload].reverse();
                this.expandedCars = (this.$isTabletForm && !this.$isRotatedMobile) ? reverse_copy : trip.passload;
            },
            closeExpandedCard(){
                this.isExpandedTrip = false;
                this.expandedIndex = null;
                this.expandedCars = null;

                if(this.closeFunc && typeof closeFunc === "function"){
                    this.closeFunc();
                    this.closeFunc = null;
                }
            },
            filterIncomplete(trips){
                const copy = [];
                const filtered = [];

                trips.forEach(t => {
                    // the records need to have all of the fields
                    if(
                        t.sched_dep_time
                        && (this.isRail || t.timing_point_id)
                        && (t.public_route || t.linecode)
                        && (this.isRail || t.lanegate)
                    ){
                        copy.push(t);
                    }
                    else{
                        filtered.push(t);
                    }
                });

                console.log("FILTER DIFFERENCE", (trips.length - copy.length), filtered);

                return copy;
            },
            createFilterList(){
                const filter = [];
                const values = {};

                // public_route

                // icon: "./rail_icons/NE_icon.png"
                // line_abbr: "NEC"
                // linecode: "NE"

                if(this.trips.length > 5){
                    this.trips.forEach(t => {
                        console.log("TRIPS FOR FILTER", this.pageType, t);
                        if(this.pageType === "rail"){
                            const line = t.linecode;
                            if(!values[line]){
                                values[line] = true;
                                filter.push({
                                    type: "rail",
                                    routeName: line,
                                    icon: t.icon,
                                    query: this.query
                                });
                            }
                        }
                        else{
                            const routeName = t.public_route;
                            if(!values[routeName]){
                                values[routeName] = true;
                                filter.push({
                                    type: this.pageType,
                                    routeName,
                                    icon: null,
                                    query: this.query
                                });
                            }
                        }
                    });
                }

                console.log("END FILTER", filter);

                if(filter.length > 1){
                    this.filterList = filter;
                }
                else{
                    this.filterList = [];
                }
            },
            extraLateTrainFilter() {
                // need to do this manually, apparently...
                console.log("EXTRA FILTER", this.trips, this.query);

                const copy = this.trips.filter(t => {
                    return t.linecode === this.query.route
                });

                this.trips = copy;
            },
            setMessages(m) {
                console.log("SET MESSAGES", m);

                if(m){
                    let messages = m;

                    if(!Array.isArray(m)){
                        messages = [m];
                    }

                    const copy = [];

                    // make sure we have actual messages
                    messages.forEach((e) => {
                        console.log("INDIVIDUAL MESSAGE", e);
                        let message = e;
                        if(Object.prototype.hasOwnProperty.call(e, "message")){
                            message = e.message;
                        }

                        if(message !== ""){
                            console.log("PUSH MESSAGE", message);
                            copy.push({message});
                        }
                    });

                    console.log("COPY OF ALL MESSAGES", copy);

                    this.message = copy;
                }
            },
            toggleAlert(){
                // we already know the terminal here from the tripObj
                console.log("THE TRIP OBJ FOR ALERT", this.$props.locationName, this.alertAlreadyActive, this.stationSub);

                this.setAlertProcessing(true);

                if(this.alertAlreadyActive && this.stationSub){
                    SubscriptionHandler.unsubscribe(this.stationAlertTopic, this.stationSub, () => {
                        console.log("BACK FROM UNSUBSCRIBE");
                        this.alertAlreadyActive = false;
                        this.stationSub = null;
                        this.stationAlertTopic = null;
                        this.setAlertProcessing(false);
                    });
                }
                else{
                    console.log("THE CURRENT STATION CODE", this.$props.query);''
                    SubscriptionHandler.setCurrentStationCode(this.$props.query.station);
                    SubscriptionHandler.stationMessageSubscribe(this.$props.locationName, (token, sub, topic) => {
                        console.log("WE HAVE A STATION SUBSCRIPTION", sub);
                        this.alertAlreadyActive = true;
                        this.stationSub = sub;
                        this.stationAlertTopic = topic;
                        this.setAlertProcessing(false);

                        alert(`You will be subscribed to ${this.$props.locationName} station alerts for 3 months.`);
                    });
                }
            },
            setAlertProcessing(val){
                console.log("STOPS CONTAINER SET ALERT PROCESSING");
                this.alertProcessing = val;
            }
        }
    }
</script>

<style scoped>

    .landscape-container .trips-container{
        position: fixed;
        width: 100vw;
        top: 9vh;
    }

    .mobile .trips-container {
        position: relative;
        top: calc(10 * var(--mvh));
    }

    .center-flow {
        display: flex;
        flex-direction: column;
        align-items: center;
        /* margin-top: 0.5vh; */
        overflow-x: hidden;
    }

    .mobile .center-flow {
        /* top: calc(10 * var(--mvh)); */
        margin-top: calc(-0.3 * var(--mvh));
    }

    .spacer {
        min-height: calc(1 * var(--mvh));
    }

    .no-trips-message {
        margin-top: 20vh;
        display: flex;
        width: 90%;
        justify-content: center;
        align-items: center;
        margin-left: 5%;
        padding: calc(2 * var(--mvh)) 0;
        font-weight: 600;
        font-size: 2.5rem;
        letter-spacing: 0.04rem;
    }

    .disconnected-message-nonscroll {
        text-align: center;
        background: black;
        color: white;
        width: 100%;
        border-top: 0.2rem solid red;
        border-bottom: 0.2rem solid red;
        font-size: 1.5rem;
        padding: 0.2rem 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .message-bell {
        color: red;
        margin: 0 0.5rem;
        font-size: 1.4rem;
    }

/*
    EXPANDED CARD STYLES
*/

    .expanded-railcar-card {
        position: fixed;
        top: 50vh;
        left: 5vw;
        width: 90vw;
        border: 1px solid #666;
        border-radius: 1rem;
        box-shadow: 0 0 20px rgba(0,0,0,0.5),
                    0 0 40px rgba(0,0,0,0.5),
                    0 0 60px rgba(0,0,0,0.5),
                    0 0 80px rgba(0,0,0,0.5);
        padding: 1rem;
        background: white;
        z-index: 1000;

        display: flex;
        flex-direction: column;

        --initial-scale: 0.5;
        --final-scale: 1;
        animation: expand-card-expand 0.15s linear forwards;
    }

    .expanded-card-backdrop {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 999;
        height: 100%;
        width: 100vw;
        backdrop-filter: blur(2px);
        background-color: rgba(0,0,0,0.4);
    }

    .card-smallscreen .expanded-railcar-card {
        padding: 0rem 1rem 3rem;
        top: 50vh;
        /* top: 8vh; */
        animation: smallscreen-expand-card 0.15s linear forwards;
    }

    .card-tablet.expanded-railcar-card {
        /* top: 8vh; */
        padding: 0rem 1rem 3rem;
        top: 50vh;
        /* top: 35vh; */
        left: 2.5vw;
        width: 95vw;
        --final-scale: 0.85;
        animation: expand-card-expand 0.15s linear forwards;
    }

    .mid-tablet.expanded-railcar-card {
        --initial-scale: 0.3;
        --final-scale: 0.75;
        animation: expand-card-expand 0.15s linear forwards;
    }

    .borderline-tablet.expanded-railcar-card {
        top: 50vh;
        /* top: 30vh; */
        --initial-scale: 0.3;
        --final-scale: 0.65;
        animation: expand-card-expand 0.15s linear forwards;
    }

    .rotated-mobile.expanded-railcar-card {
        top: 50vh;
        /* top: 35vh; */
        padding: 1rem 1rem 0;
    }

    .mobile .expanded-railcar-card {
        top: 45vh;
    }

    @keyframes expand-card-expand {
        0% {
            transform: translateY(-32%) scaleY(0) scaleX(var(--initial-scale));
        }
        100% {
            transform: translateY(-50%) scaleY(var(--final-scale)) scaleX(var(--final-scale));
        }
    }

    @keyframes smallscreen-expand-card {
        0% {
            transform: translateY(-32%) scaleY(0) scaleX(0.5);
        }
        100% {
            transform: translateY(-50%) scaleY(1) scaleX(1);
        }
    }

    .expanded-close-button {
        position: absolute;
        top: 1.2rem;
        right: 1rem;
        /* border: 1px solid #666; */
        border-radius: 2rem;
        padding: 0.5rem 0.65rem 0.5rem 0.65rem;
        cursor: pointer;
        transition: 0.2s;
        background-color: #ddd;
        font-weight: bold;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    .card-smallscreen .expanded-close-button {
        top: initial;
        bottom: 0rem;
        right: 0rem;
        padding: 0.65rem 0.75rem 0.65rem 0.85rem;
        border-radius: 0;
        border-bottom-right-radius: 1rem;
        border-top-left-radius: 1rem;
        border: none;
    }

    .expanded-close-button:hover {
        background-color: #ccc;
    }

    .car-label {
        font-size: 1.6rem;
        font-weight: bold;
        /* margin-bottom: 0.75rem;
        align-self: flex-end; */
        margin-right: 1rem;

        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .card-smallscreen .car-label {
        align-self: initial;
        margin-right: initial;
    }

    .rotated-mobile .car-label {
        font-size: 1rem;
    }

    .expanded-details {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
        align-items: center;
    }

    .card-smallscreen .expanded-details {
        /* flex-direction: row; */
        margin-top: 0.5rem;
        display: grid;
        grid-template-columns: 3fr 8fr;
    }

    .card-smallscreen.card-tablet .expanded-details {
        grid-template-columns: 3fr 5fr;
    }

    .rotated-mobile .expanded-details {
        margin-top: -1rem;
    }

    .mobile-cars-line-container {
        display: grid; 
        grid-template-columns: 5fr 2fr; 
        align-self: flex-start; 
        padding-top: 1rem; 
        height: 100%;
    }

    .ios .mobile-cars-line-container {
        height: auto;
    }

    .cars {
        display: flex;
        gap: 0.5rem;
    }
    
    .card-smallscreen .cars {
        flex-direction: column;
        gap: 0.35rem;
        padding: 0 1rem;
    }

    .expanded-car {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        align-items: center;

        justify-content: center;
        height: 100%;

        position: relative;
    }

    .card-smallscreen .expanded-car {
        flex-direction: row;
    }

    .expanded-car.first-in-car {
        border-left: 1px dashed #bbb;
        padding-left: 0.25rem;
    }

    .card-smallscreen .expanded-car.first-in-car {
        border-left: none;
        border-top: 1px dashed #bbb;
        padding-left: initial;
        padding-top: 0.35rem;
    }

    .expanded-car-id {
        font-size: 1.1rem;
        font-weight: bold;
    }

    .expanded-car-element {
        width: 3.5rem;
        height: 1.75rem;
        border: 1px solid #444;
        border-radius: 0.25rem;

        display: grid;
        place-content: center;
    }

    .borderline-laptop .expanded-car-element
    {
        width: 2.8rem;
        height: 1.5rem;
    }

    .rotated-mobile .expanded-car-element {
        width: 2rem;
        height: 1.2rem;
    }

    .first-car-of-train .expanded-car-element {
        border-top-right-radius: 1.5rem;
    }

    .card-smallscreen .expanded-car-element {
        height: 1.5rem;
        width: 0.75rem;
    }

    .expanded-rest {
        height: 1.4rem;
        width: 1.4rem;
        background-size: 100%;
    }

    .borderline-laptop .expanded-rest
    {
        height: 1.2rem;
        width: 1.2rem;
    }

    .rotated-mobile .expanded-rest {
        height: 1rem;
        width: 1rem;
    }

    .dark .expanded-rest {
        background-image: url("~@/assets/Restroom Icon (white).svg");
    }

    .card-smallscreen .expanded-rest,
    .yellow-car .expanded-rest {
        height: 1.5rem;
        width: 1.5rem;
        background-image: url("~@/assets/Restroom Icon (Blk).svg");
    }

    .rotated-mobile .card-smallscreen .expanded-rest,
    .rotated-mobile .yellow-car .expanded-rest {
        height: 1rem;
        width: 1rem;
    }

    .yellow-line {
        width: 100%;
        height: 1rem;
        background-color: #ff0;
        border: 1px solid #ee1;
        margin-top: 1.2rem; 
    }

    .card-smallscreen .yellow-line {
        height: 100%;
        width: 0.5rem;
        margin-top: initial;
    }

    .card-smallscreen.card-tablet .yellow-line {
        width: initial;
    }

    .rotated-mobile .yellow-line {
        height: 0.5rem;
    }

    .yellow-line-message {
        width: 100%;
        text-align: center;
        font-size: 1rem;
        font-weight: bold;
        background-color: #aaa;
        padding: 0.5rem 1rem;
        border: 1px solid #aaa;
    }
    
    .card-smallscreen .yellow-line-message {
        font-size: 1.2rem;
        letter-spacing: 0.02rem;
        width: 2rem;
        writing-mode: vertical-rl;
        transform: scale(-1);
        padding: 1rem 1rem 1rem 2rem;
    }

    .rotated-mobile .yellow-line-message {
        font-size: 0.7rem;
        padding: 0.3rem 1rem;
    }

    .expanded-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5em;
        padding: 1rem 1.5rem;
    }

    .card-smallscreen .expanded-content {
        justify-content: space-between;
        height: 100%;
        padding: 1rem 1.5rem 0;
    }

    .ios.card-smallscreen .expanded-content {
        height: auto;
    }

    .laptop-expanded-content {
        width: 100%;
        display: flex;
        gap: 1rem;
        margin-top: -1rem;
        margin-bottom: 2rem;
        padding: 1rem 0;
    }

    .expanded-destination {
        font-weight: bold;
        font-size: 1.5rem;
    }

    .laptop-expanded-destination {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 1rem;
    }

    .laptop-expanded-destination .rotated {
        margin-left: initial;
        margin-right: 1rem;
    }

    .rotated-mobile .expanded-destination {
        font-size: 1rem;
    }

    .color-key {
        display: grid;
        grid-template-columns: 1fr 3fr;
        gap: 0.5rem;
        justify-content: center;
        align-items: center;
        margin: 1rem 0;
    }

    .rotated-mobile .color-key {
        gap: 0.3rem;
    }

    .color-code {
        height: 1.3rem;
        width: 2rem;
        border: 1px solid #444;
        border-radius: 0.2rem;
    }

    .card-smallscreen .color-code {
        height: 1.1rem;
        width: 1.1rem;
    }

    .rotated-mobile .color-code {
        height: 0.9rem;
        width: 1.1rem;
    }

    .color-code-green {
        background-color: green;
    }

    .color-code-yellow {
        background-color: #ff0;
    }

    .color-code-red {
        background-color: red;
    }

    .color-code-gray {
        background-color: #666;
    }

    .expanded-message {
        width: 100%;
        border: 1px solid #666;
        padding: 1rem;
        margin-top: 2rem;
        margin-bottom: -2rem;
    }

    .card-smallscreen .expanded-message {
        margin-top: initial;
        margin-bottom: initial;
    }

    .rotated-mobile .expanded-message {
        font-size: 0.8rem;
        padding: 0.5rem;
        margin-top: 1rem;
    }

    .laptop-main-expanded-content {
        width: 100%;
        display: grid;
        /* margin-top: -1rem; */
        grid-template-columns: 1fr 7fr;
    }

    .laptop-main-expanded-content .cars {
        align-self: center;
        justify-self: center;
    }

    .borderline-laptop .laptop-main-expanded-content .cars *
    {
        /* font-size: smaller; */
        font-size: 1.1rem;
    }

    .rotated-mobile .expanded-car-id {
        font-size: 0.8rem;
    }

    .laptop-expanded-trip-display-container {
        /* margin-left: -0.8rem; */
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .laptop-expanded-colors {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        background: #eee;
        padding: 0.7rem 0.35rem 0.35rem 0.35rem;
        border-radius: 0.2rem;
    }

    .laptop-expanded-colors .color-key {
        margin: 0;
    }

    .rotated-mobile .laptop-expanded-colors {
        gap: 0.3rem;
        padding: 0.4rem 0.3rem;
        align-items: center;
        justify-content: center;
    }

    .rotated-mobile .laptop-expanded-colors * {
        font-size: 0.8rem;
    }

    .station-alert-container {
        width: 100%;
        /* margin-top: 0.5rem; */
        padding-left: 1vw;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .station-alert-button {
        margin: 0.5rem 0;
        cursor: pointer;
        display: flex;
        align-items: center;

        padding: 0.4rem 0.7rem;
        border-radius: 1rem;
        border: 2px solid black;
    }

    .mobile .station-alert-button {
        margin-left: 1vw;

        padding: 0.55rem 0.7rem;
        border-radius: 2rem;
        text-align: center;
        font-size: 0.75rem;
    }

    .station-alert-button:hover {
        opacity: 0.8;
    }

    .station-alert-button .button-text {

    }

    .mobile .station-alert-button .button-text {
        
    }

    .base {
        background-color: white; 
        color: black;
    }

    .active {
        background-color: gold;
        color: black;
    }

    .processing {
        animation: processing-pulse 1s linear infinite;
    }

    @keyframes processing-pulse {
        0% {
            color: currentColor;
            box-shadow: 0 0 0 gold;
        }
        50% {
            background-color: gold;
            box-shadow: 0 0 3rem gold;
        }
        100% {
            color: currentColor;
            box-shadow: 0 0 0 gold;
        }
    }

    .alert_filter_container {
        width: 100%; 
        display: flex; 
        align-items: center; 
        justify-content: space-evenly;
    }

    .mobile .alert_filter_container {
        margin-top: calc(0.8* var(--mvh));
    }

    .tablet .alert_filter_container {
        margin-top: 0.3rem;
    }

</style>