
Shell for the StopsContainer component, which handles the 
query parameters, resizing, and navigation

Uses the vuetify v-btn component for a bit of animation


:style="{fontSize: appliedFontSize + 'vh'}"


<!-- <v-btn
v-if="true || hasStopOrStation"
elevation="2"
@click="this.navigateBack"
>
<span class="one-line">
    BACK
</span>
</v-btn> -->

<!--
    !hasStopOrStation
        !$isTabletForm
            ICON
            ROUTE/ RAIL LINE NAME
    
        $data.query.fromMap
            DESTINATION
        else tripObj.vehicle_id
            DESTINATION
        else $data.query.tripid
            DESTINATION
    
        $isTabletForm
            ICON
            ROUTE/RAIL LINE NAME
    (else) !$data.query.fromMap          (these cases are supposed to be mutually exclusive?)
        !$isTabletForm
            ICON
            ROUTE
    
        DESTINATION
    
        $isTabletForm
            ICON
            ROUTE
        else pageType === 'rail'
            MAP ICON ? (not sure about this one here...)
-->



<template>
    <div 
        :class="($isTabletForm ? 'smallscreen' : '')"
    >
        <SimplifiedHeader
            :showLogo="!$isMobile"
            :logoClass="'njt-icon' + (($isMidTablet || $isBorderlineTablet) ? ' large-tablet-icon' : '')"
            @logo-click="redirectToLanding"
            :backButtonClass="'back-button-container ' + ($isMobile ? 'back-button-no-logo' : '')"
            :backButtonText="'BACK'"
            @back-button-click="this.navigateBack"
            :titleClass="($isTabletForm ? 'title-spaced' : '')"
            :titleText="destination ? (titleText + ' ' + destination).toLowerCase() : ''"
            :titleTextClass="'title stop-title ' + ($isTablet ? ' extra-spacer ' : '') + (($isMobile && locationName.length > 25) ? ((($isMobile && locationName.length > 25)) ? ' extra-large-name' : ' large-name' ) : '')"
            :pageType="pageType"
            :query="query"
            :headerClass="$isTabletForm ? 'tablet-form-header' : ''"
            :backIcon="$isTabletForm"
            :busRoute="busRoute"
            :iconFollowUp="''/*railLineName*/"
            :iconClass="destination ? ((!$isTabletForm || pageType !== 'rail') ? iconClass : '') : iconClass"
            :iconStyle="(!$isTabletForm && (pageType === 'rail' || pageType === 'lightrail')) ? ((pageType === 'rail' || pageType === 'lightrail')
                ? `width: ${$isMobile ? '12vw' : ($data.query.fromMap ? '3.5rem' : 'min(5vw, calc(7 * var(--mvh)))')}; 
                                height: ${$isMobile ? '12vw' : ($data.query.fromMap ? '3.5rem' : 'min(5vw, calc(7 * var(--mvh)))')}; 
                                background-image: url(./line_icons/${tripObj.route}_icon.png); 
                                background-size: contain; 
                                background-position: center center;`
                : `
                        width: 3.5rem;
                        height: 3.5rem;
                        ${$isTabletForm ? 'margin-left: 7vw;' : ''}
                    `) : ($isTabletForm && pageType === 'lightrail') ? 
                        `
                            width: 3.5rem;
                            height: 3.5rem;
                            margin-left: 7vw;
                            background-image: url(./line_icons/${query.route}_icon.png); 
                            background-size: contain; 
                            background-position: center center;`
                    : ''
            "
            :showMapIcon="pageType === 'rail'"
            :mapIconClass="mapIconClass"
            :mapButtonStyle="`border: none; background-image: url(./images/map_icon.png); background-size: contain; background-position: center; cursor: pointer;`"
            @map-button-click="navigateToMapPath"
        >
        </SimplifiedHeader>

        <div>
            AA{{ tripObj.route }}BB
        </div>

        <LaptopNavBar v-if="false && !$isTabletForm" :condensed="true" :pageType="pageType" :query="query" :hideText="true" tripObj="tripObj" />

        <div v-if="false" :class="'full-nav' + ((!$isTabletForm) ? ' collapsed-nav' : '') + ($isRotatedMobile ? ' rotated' : '')">
            <div v-if="$isMidTablet || $isBorderlineTablet" class="njt-icon" @click="redirectToLanding"></div>
            <div :class="'location-button' + (($isMidTablet || $isBorderlineTablet) ? ' large-tablet-button' : '')">
                <SimplifiedButton
                    v-if="true || hasStopOrStation"
                    @click-event="this.navigateBack"
                >
                    <span class="one-line">
                        <!-- {{($isTabletForm || locationName === " " || locationName === "STOP # ") ? "BACK" : locationName}} -->
                        BACK
                    </span>
                </SimplifiedButton>
            </div>


            <div v-if="!hasStopOrStation"
                class="f1"
                :style="`
                    display: flex; 
                    align-items: center; 
                    ${$isTabletForm ? 'justify-content: flex-end;' : 'justify-content: space-betwen;'} 
                    width: 100%; 
                    margin-left: 1rem;
                    ${($data.query.fromMap || $data.query.fromHomeNearby) ? '' : 'margin-top: 1vw'};
                `"
            >


                <div v-if="!$isTabletForm">
                    <div v-if="pageType === 'rail' || pageType === 'lightrail'" 
                        :style="`width: ${$isMobile ? '12vw' : ($data.query.fromMap ? '3.5rem' : 'min(5vw, calc(7 * var(--mvh)))')}; 
                            height: ${$isMobile ? '12vw' : ($data.query.fromMap ? '3.5rem' : 'min(5vw, calc(7 * var(--mvh)))')}; 
                            background-image: url(./line_icons/${tripObj.route}_icon.png); 
                            background-size: contain; 
                            background-position: center center;`"
                    >
                    </div>

                    <div v-if="pageType === 'rail' && !$data.query.fromMap"
                        class="rail-name f2"
                        :style="`
                            ${$data.query.fromMap ? 'margin-right: 1rem;' : ''}
                            ${$isTabletForm ? 'background: rgba(0,0,0,0.5); padding: 0.25rem; border-radius: 0.5rem; color: white;' : ''}
                            ${$isTabletForm ? 'margin-left: 7vw;' : ''}
                        `"
                    >
                        {{railLineName}}
                    </div>
                    <div v-else-if="pageType === 'bus'" class="route-number"
                        :style="`
                            width: 3.5rem;
                            height: 3.5rem;
                            ${$isTabletForm ? 'margin-left: 7vw;' : 'margin-right: 1rem;'}
                        `"
                    >
                        {{tripObj.route}}
                    </div>
                </div>



                <div class="vehicle-id" v-if="$data.query.fromMap/* || $data.query.fromHomeNearby*/">
                    {{ 
                        pageType === "rail"
                        ? "Train "
                        : ""
                    }}
                    {{
                        pageType === "lightrail"
                        ? (($data.query.fromNearby || $data.query.fromHomeNearby) ? "Bus " : "Train ")
                        : remove0Num($data.query.vid)
                    }}
                    to
                    {{destination}}
                </div>
                <div class="vehicle-id" v-else-if="tripObj.vehicle_id">
                    {{ 
                        pageType === "rail"
                        ? "Train "
                        : ""
                    }}
                    {{
                        pageType === "lightrail"
                        ? (($data.query.fromNearby || $data.query.fromHomeNearby) ? "Bus " : "Train ")
                        : remove0Num(tripObj.vehicle_id)
                    }}
                    to
                    {{destination}}
                </div>
                <div class="vehicle-id" v-else-if="$data.query.tripid">
                    <!-- #{{$data.query.tripid}} -->
                    {{ 
                        pageType === "rail"
                        ? "Train "
                        : ""
                    }}
                    {{
                        pageType === "lightrail"
                        ? (($data.query.fromNearby || $data.query.fromHomeNearby) ? "Bus " : "Train ")
                        : remove0Num($data.query.tripid)
                    }}
                    to
                    {{destination}}
                </div>


                <div v-if="$isTabletForm">
                    <div v-if="pageType === 'rail' && false" 
                        :style="`width: ${$isMobile ? '12vw' : ($data.query.fromMap ? '3.5rem' : 'min(5vw, calc(7 * var(--mvh)))')}; 
                                height: ${$isMobile ? '12vw' : ($data.query.fromMap ? '3.5rem' : 'min(5vw, calc(7 * var(--mvh)))')}; 
                                background-image: url(./line_icons/${tripObj.route}_icon.png); 
                                background-size: contain; 
                                background-position: center center;`"
                    >
                    </div>
                    <div v-else-if="pageType === 'lightrail'"
                        :style="`width: ${$isMobile ? '12vw' : ($data.query.fromMap ? '3.5rem' : 'min(5vw, calc(7 * var(--mvh)))')}; 
                                height: ${$isMobile ? '12vw' : ($data.query.fromMap ? '3.5rem' : 'min(5vw, calc(7 * var(--mvh)))')}; 
                                background-image: url(./line_icons/${tripObj.route}_icon.png); 
                                background-size: contain; 
                                background-position: center center;`"
                    ></div>

                    <div v-else 
                        :style="`width: ${$isMobile ? '12vw' : ($data.query.fromMap ? '3.5rem' : 'min(5vw, calc(7 * var(--mvh)))')}; 
                            height: ${$isMobile ? '12vw' : ($data.query.fromMap ? '3.5rem' : 'min(5vw, calc(7 * var(--mvh)))')}; 
                            background-image: url(./images/map_icon.png); 
                            background-size: contain; 
                            background-position: center center;`"
                    >
                    </div>

                    <div v-if="pageType === 'rail' && !$data.query.fromMap"
                        class="rail-name"
                        :style="`
                            ${$data.query.fromMap ? 'margin-right: 1rem;' : ''}
                            ${$isTabletForm ? 'background: rgba(0,0,0,0.5); padding: 0.25rem; border-radius: 0.5rem; color: white;' : ''}
                            ${$isTabletForm ? 'margin-left: 7vw;' : ''}
                        `"
                    >
                        {{railLineName}}
                    </div>
                    <div v-else-if="pageType !== 'rail'" class="route-number"
                        :style="`
                            width: 3.5rem;
                            height: 3.5rem;
                            ${$isTabletForm ? 'margin-left: 7vw;' : ''}
                        `"
                    >
                        {{tripObj.route}}
                    </div>
                </div>
            </div>







            <div 
                v-if="!$data.query.fromMap /*&& !$data.query.fromHomeNearby*/"
                class="f3"
                :style="`display: flex;
                    flex-direction: row;
                    align-items: center;
                    ${$isTabletForm ? 'justify-content: flex-end;' : ''}
                    margin-left: 1rem;
                    ${$isTabletForm ? 'padding-left: 7vw;' : ''}
                    `
            ">
                <div v-if="!$isTabletForm">
                    <div v-if="pageType === 'rail' || pageType === 'lightrail'" 
                        :class="'route-number' + (pageType === 'rail' ? ' rail-button' : '')"
                        :style="`background-image: url(./line_icons/${tripObj.route}_icon.png); background-size: contain; background-position: center;`"
                    >
                    </div>

                    <div v-else class="route-number">
                        {{tripObj.route}}
                    </div>
                </div>

                <div class="vehicle-id padded">
                    <!-- {{(($data.query.tripid && !tripObj) || tripObj.vehicle_id) ? "#" : ""}} -->
                    {{ 
                        pageType === "rail"
                        ? "Train "
                        : ""
                    }}
                    {{
                        pageType === "lightrail"
                        ? (($data.query.fromNearby || $data.query.fromHomeNearby) ? "Bus " : "Train ")
                        : remove0Num(((pageType === 'rail') ? $data.query.tripid : (tripObj ? tripObj.vehicle_id : "")))
                    }}
                    to
                    {{destination}}
                </div>

                <div v-if="$isTabletForm">
                    <div v-if="pageType === 'rail'" 
                        :class="'route-number' + (pageType === 'rail' ? ' rail-button' : '')"
                        :style="`border: none; background-image: url(./images/map_icon.png); background-size: contain; background-position: center; cursor: pointer;`"
                        @click="navigateToMapPath"
                    ></div>
                    <div v-else-if="pageType === 'lightrail'"
                        :class="'route-number' + (pageType === 'rail' ? ' rail-button' : '')"
                        :style="`background-image: url(./line_icons/${tripObj.route}_icon.png); background-size: contain; background-position: center;`"
                    ></div>
                    <div v-else class="route-number">
                        {{tripObj.route}}
                    </div>
                </div>
                <div v-else-if="pageType === 'rail'"
                        :class="'route-number' + (pageType === 'rail' ? ' rail-button' : '')"
                        :style="`border: none; background-image: url(./images/map_icon.png); background-size: contain; background-position: center; cursor: pointer;`"
                        @click="navigateToMapPath">
                </div>
            </div>







        </div>
        <StopsContainer 
            :query="$data.query" 
            :overflow="overflow" 
            :mainDisplayHeightReduction="$props.mainDisplayHeightReduction"
            :tripObj="tripObj"
            @setTripObj="setTripObj"
            @setDestination="setDestination"
        />
    </div>
</template>

<script>
    import StopsContainer from "../components/StopsContainer";
    import { getName } from "@/NameMappings";

    import { getLocations } from "@/requests/OptionRequests";
    import { setName } from "@/NameMappings";

    import LaptopNavBar from "@/components/LaptopNavBar";
    // import MapButton from "@/components/MapButton";

    import SimplifiedButton from "@/components/SimplifiedButton";
    import SimplifiedHeader from "@/components/SimplifiedHeader";

    import { executeKillSwitch } from "@/requests/BaseRequestValues";

    const BASE_STORAGE_KEY = "__-StopIDSelection-__";
    const __SELECTED_DEFAULT_PAGE_KEY__ = "__SELECTED_DEFAULT_PAGE_KEY__";

    import { 
        setQueryParameters, 
        onlyNonNullValues, 
        determineDefaultFontSize, 
        decryptObject,
        removeLead0 } from "@/Utilities";

    export default {
        name: "Stops",
        props: ["mainDisplayHeightReduction"],
        components: {
            StopsContainer,
            LaptopNavBar,
            SimplifiedButton,
            SimplifiedHeader
            // MapButton
        },
        data() {
            return {
                query: {
                    layout: "",
                    fontSize: "",
                    scrolling: "",
                    route: "",
                    direction: "",
                    stop: "",
                    station: "",
                    status: "",
                    hidenav: null,
                    tripid: "",
                    late: null,
                    footer: null,
                    fromMap: null,
                    fromHomeNearby: null,
                    vid: null,
                    dark: null,
                    fromPlanner: null,
                    from: null,
                    to: null,
                    fromNearby: null,
                    busRoute:  null
                },
                overflow: false,
                appliedFontSize: null,
                tripObj: null,
                pageType: null,
                hasStopOrStation: false,
                railLineName: "",
                locationName: "",
                destination: "",
                titleText: "",
                iconClass: "route-number",
                iconStyle: "",
                showMapIcon: "",
                mapIconClass: "route-number",
                mapButtonStyle: ""
            }
        },
        watch: {
            destination(oldVal, newVal){
                console.log("SET DESTINATION WATCH", oldVal, newVal);
            }
        },
        created() {
            setQueryParameters(this);
            this.appliedFontSize = determineDefaultFontSize(this.query, this.$mq);

            this.setOverflow();

            window.addEventListener("resize", this.resizeHandler);

            console.log("STOPS QUERY PARAM", this.query.tripid, this.query, this.$route.query);

            const pageType = this.$route.path.split("/")[1];
            this.pageType = pageType;

            if(this.query.tripid && pageType !== "rail"){
                let obj = null;
                try{
                    const translated = decodeURIComponent(this.query.tripid);
                    console.log("INVALID TRANSLATED TRIP?", this.query.tripid, translated);
                    obj = decryptObject(translated);
                    console.log("DECRYPTED TRIP", obj);
                    this.tripObj = this.tripObjRouteFix(obj);
                }
                catch(ex){
                    alert("Invalid Stop URL");
                    console.log("INVALID STOP", ex, ex.message);
                    return;
                }
                console.log("INVALID OBJECT BACK?", obj);
            }
            else if(pageType === "rail"){
                this.tripObj = { tripid: this.query.tripid, route: this.query.route };
                console.log("BEFORE GET STOP NAME", this.query);
                this.railLineName = getName(this.query.station, "rail-line");
            }

            const loc = this.query.station || this.query.stop;

            console.log("DO WE HAVE A STATION?", loc, this.query);

            if(loc){
                this.hasStopOrStation = true;
            }

            // get locations
            const retrieved = this.retrieveSelectionsFromStorage();

            console.log("STATION RETRIEVED", retrieved);

            if(!retrieved){
                console.log("CHECK ON STATIONS");

                const params = {
                    mode: null
                };

                // "/bus", "/lightrail", "/riverline"
                switch(this.pageType){
                    case "bus":
                        params.mode = "BUS";
                        break;
                    case "lightrail":
                        params.mode = "NLR";
                        break;
                    case "riverline":
                        params.mode = "RL";
                        break;
                    case "rail":
                        params.mode = "RAIL";
                        this.isStop = false;
                        this.active_tab = 2;
                        this.showTabs = false;
                        break;
                }

                getLocations(params)
                .then(({data}) => {
                    console.log("GET STATION DATA YYY", data);
                    data.forEach(location => {
                        setName(location.bus_terminal_code, location.bus_terminal_name, this.pageType + "-stop");
                    });

                    this.locationName = getName(loc, `${pageType}-stop`) || loc;
                    console.log("LOCATIONNAME", this.locationName);
                });
            }
            else {
                console.log("DO WE HAVE THE STATIONS ALREADY?");
                this.locationName = getName(loc, `${pageType}-stop`) || loc;
                console.log("LOCATIONNAME", this.locationName);
            }

            //
            //
            // THE NEW WAY
            //
            //

            let titleString = (pageType === "rail" ? "Train " : "");

            if(pageType === "lightrail"){
                titleString += ((this.$data.query.fromNearby || this.$data.query.fromHomeNearby) ? "Bus " : "Train ");
            }
            else{
                titleString += this.remove0Num(this.$data.query.fromMap 
                                            ? this.$data.query.vid 
                                            : ((pageType === 'rail') ? this.$data.query.tripid : (this.tripObj ? this.tripObj.vehicle_id : "")));
            }

            titleString += " to ";
            this.titleText = titleString;

            this.showMapIcon = this.$data.query.fromMap || (pageType !== "lightrail" && this.$isTabletForm);

            // iconStyle: "",
            // mapButtonStyle: ""

            // let icon = "";
            // if(pageType === "rail" || pageType === "lightrail"){
            //     icon = `width: ${$isMobile ? '12vw' : (this.$data.query.fromMap ? '3.5rem' : 'min(5vw, calc(7 * var(--mvh)))')}; 
            //                 height: ${$isMobile ? '12vw' : (this.$data.query.fromMap ? '3.5rem' : 'min(5vw, calc(7 * var(--mvh)))')}; 
            //                 background-image: url(./line_icons/${this.tripObj.route}_icon.png); 
            //                 background-size: contain; 
            //                 background-position: center center;`;
            // }
            // else if(pageType === "bus"){
            //     icon =  `
            //         width: 3.5rem;
            //         height: 3.5rem;
            //         ${$isTabletForm ? 'margin-left: 7vw;' : ''}
            //     `;
            // }
        },
        destroyed() {
            window.removeEventListener("resize", this.resizeHandler);
            console.log("DESTROY STOPS");
            executeKillSwitch();
        },
        methods: {
            remove0Num(val) {
                if(!val || val.trim().toUpperCase() === "NULL"){
                    return "";
                }

                let v = val;
                if(val && val.trim().toUpperCase() === "EMPTY"){
                    v = this.tripObj.route;
                    return v;
                }
                return "#" + removeLead0(v);
            },
            setDestination(dest){
                console.log("SET DESTINATION CAPTURE", dest);
                this.destination = dest;
            },
            redirectToLanding() {
                // need to remove values from local storage?
                localStorage.removeItem(__SELECTED_DEFAULT_PAGE_KEY__);

                this.$router.push({
                    path: `/`
                });
            },
            navigateBack(evt){
                if(evt){
                    evt.preventDefault();
                }

                if(this.query.fromMap){
                    this.$router.push({
                        path: 'map'
                    });
                    return;
                }
                else if(this.query.fromPlanner){
                    this.$router.push({
                        path: "Station",
                        query: {
                            GetTripFrom: this.query.from,
                            To: this.query.to
                        }
                    });
                    return;
                }
                
                const nonTrip = {...this.$data.query};

                nonTrip.tripid = null;

                if(this.pageType !== "lightrail"){
                    // nonTrip.route = null;
                }

                console.log("LOCATIONNAME", "A"+this.locationName+"A");

                if(this.locationName === " " || this.locationName === "STOP # "){
                    this.$router.push({
                        path: 'home',
                        query: onlyNonNullValues(nonTrip) 
                    });
                }
                else{
                    this.$router.push({
                        path: 'trips',
                        query: onlyNonNullValues(nonTrip) 
                    });
                }

            },
            navigateBackHome(evt){
                if(evt){
                    evt.preventDefault();
                }

                this.$data.query.route = null;
                this.$data.query.direction = null;
                this.$data.query.stop = null;
                this.$data.query.station = null;
                this.$data.query.tripid = null;

                this.$router.push({
                    path: `/${this.pageType}/home/`,
                    query: onlyNonNullValues(this.$data.query) 
                });
            },
            navigateToDepartures(evt) {
                if(evt){
                    evt.preventDefault();
                }

                const nonTripOrStop = {...this.$data.query};
                nonTripOrStop.tripid = null;
                nonTripOrStop.route = this.tripObj.route;

                console.log("NAVIGATE TO DEPARTURES", nonTripOrStop);

                this.$router.push({
                    path: 'trips',
                    query: onlyNonNullValues(nonTripOrStop) 
                });
            },
            navigateToMapPath(evt){
                // how do I get the route?
                if(evt){
                    evt.preventDefault();
                }

                const nonTripOrStop = {...this.$data.query};
                nonTripOrStop.tripid = null;
                nonTripOrStop.linedisplay = this.tripObj.route;
                nonTripOrStop.station = this.$data.query.station;
                nonTripOrStop.tripid = this.$data.query.tripid;
    
                console.log("NAVIGATE TO DEPARTURES", nonTripOrStop);
    
                this.$router.push({
                    path: 'map',
                    query: onlyNonNullValues(nonTripOrStop) 
                });
            },
            setOverflow(){
                this.overflow = this.$isMobile || this.query.scrolling === 0;
                // this.overflow = !((
                //     (this.$mq === 'large' && this.query.layout !== 'm') 
                //     || this.query.layout === 'l'
                // ) && this.query.scrolling != 0);
            },
            resizeHandler() {
                this.setOverflow();
            },
            setTripObj(obj) {
                console.log("SET TRIP OBJ", obj);
                // kind of a hack, but I'm not going to totally restructure this now...
                const trip = this.tripObjRouteFix(obj);
                this.tripObj = trip

                if(this.pageType === "bus"){
                    this.busRoute = trip.route;
                }

                this.railLineName = getName(obj.route, "rail-line");
            },
            tripObjRouteFix(obj){
                if(this.pageType === "lightrail"){
                    if(this.query.route && this.query.route.toUpperCase() === "RL"){
                        obj.route = "RL";
                    }
                    else if(this.query.route && this.query.route.toUpperCase() === "HBLR"){
                        obj.route = "HB";
                    }
                    else {
                        obj.route = "NLR";
                    }
                }
                return obj;
            },
            retrieveSelectionsFromStorage(){
                const str = localStorage.getItem(BASE_STORAGE_KEY + this.pageType);
                if(str){
                    const obj = JSON.parse(str);
                    if(obj){

                        console.log("STOP ID SELECTION OBJECT ON LOAD", obj);

                        // set all the keys
                        Object.keys(obj).forEach(key => {
                            this[key] = obj[key];
                        });

                        if(this.active_tab == 1){
                            console.log("STOP ACTIVE TAB 1");
                        }
                        else{
                            console.log("STOP NOT ACTIVE TAB 1", this.active_tab);
                        }

                        return true;
                    }
                }
                return false;
            }
        }
    }
</script>

<style>
    .tablet-form-header {
        position: fixed !important;
        top: 0;
        left: 0;
    }

    .title.stop-title {
        font-size: 1.5rem;
    }

    .smallscreen .title.stop-title {
        font-size: 1.15rem;
        /* padding-left: 1.5rem; */
        position: absolute;
        left: 50vw;
        transform: translateX(calc(4vw - 50%));
        width: 57vw;
    }

    .tablet .title.stop-title {
        transform: translateX(-50%);
    }

    .smallscreen .title-spaced {
        /* justify-content: space-between; */
        justify-content: flex-end;
        padding-right: 1rem;
    }

    .back-button-no-logo {
        margin-left: 1rem;
    }

    .route-number {
        background-color: var(--primary-color);
        border-radius: 50%;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;

        width: min(4vw, calc(8 * var(--mvh)));
        height: min(4vw, calc(8 * var(--mvh)));

        border: 2px solid white;

        font-family: Roboto, sans-serif;
        font-weight: 500;
        /* width: 5vw;
        height: 5vw;
        font-size: 1.2rem; */

        font-size: 1.15rem;

        padding: 0.2vw;
        aspect-ratio: 1;
    }

    .mobile .route-number,
    .tablet .route-number {
        width: 8.5vh;
        height: 8.5vh;
        font-size: 1.25rem;
        border: 0.2rem solid white;
    }

    .rail-button.route-number {
        padding: 15px;
    }
</style>

<style scoped>
    a {
        font-weight: normal !important;
    }

    .full-nav {
        position: relative;
        /* width: 70%; */
        gap: 2rem;
        /* padding-left: 6.5rem; */
        justify-content: flex-start;
        margin: 0 auto;
    }

    .collapsed-nav {
        position: absolute;
        top: calc(-0.5 * var(--mvh));
        left: 6.5rem;
    }

    .rail-name {
        font-size: 1.7rem; 
        margin-left: 1rem;
    }

    .dark .rail-name {
        color: white;
    }

    .mobile .full-nav {
        /* width: 100%; */
        padding: 0 calc(1.2 * var(--mvh));
        /* width: 95%; */
        /* padding-left: 0; */
        gap: 0rem;
        justify-content: space-between;

        background-color: var(--primary-color);
        box-shadow: 0 0 10px var(--primary-color);

        overflow: hidden;
    }

    .mobile .full-nav.rotated .v-btn {
        transform: scale(0.9);
    }

    .mobile .full-nav.rotated .button {
        transform: scale(0.9);
    }



    .location-button .v-btn {
        max-width: 60vw !important;
        z-index: 1;
    }

    .location-button .button {
        max-width: 60vw !important;
        z-index: 1;
    }

    .vehicle-id {
        font-size: 1.5rem; 
        margin: 0 0.8vw 0 0;
        max-width: 40vw;
    }

    .padded {
        color: white;
        padding-left: 1rem;
    }

    .mobile .vehicle-id {
        font-size: 1.15rem;
        color: white;
        margin: 0 0.25rem;

        padding: 0 !important;

        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        max-width: 60vw;
    }

    .dark .vehicle-id {
        color: white;
    }

    .njt-icon {
        background: url("../assets/hug-icon-s.svg");
        background-repeat: no-repeat;
        background-size: cover;
    }

    .smallscreen .njt-icon {
        position: relative;
        margin-left: 1rem;
        width: calc(5.3 * var(--mvh));
        height: calc(6.6 * var(--mvh));
    }

    .smallscreen .njt-icon::after {
        content: "";
        position: absolute;
        background-color: white;
        height: 100%;
        width: 2px;
        top: 0%;
        right: -1rem;
    }

    .dark .njt-icon,
    .mobile .njt-icon,
    .smallscreen .njt-icon {
        background: url("../assets/hug-icon-s-white.svg");
        background-repeat: no-repeat;
        background-size: cover;
    }

    .large-tablet-button {
        position: absolute;
        left: calc(5.3 * var(--mvh) + 3.5rem);
    }

</style>