<template>
    <div class="leg" @click="gotoStops" :style="style">
        <div class="icon" :style="iconStyle">
        </div>
        <div v-if="$isTabletForm" class="content">
            <div class="indicator-box">
                <div v-if="isFirst" class="indicator first" :style="indicatorBorderStyle"></div>
                <div v-else class="indicator" :style="baseIndicatorStyle"></div>
                {{leg.STATION_NAME_ON}}
            </div>
            <div class="indicator-box">
                <div v-if="isLast" class="indicator last" :style="indicatorBorderStyle"></div>
                <div v-else class="indicator" :style="baseIndicatorStyle"></div>
                {{leg.STATION_NAME_OFF}}
            </div>
            <div>
                {{removeDate(leg.SCHED_DEP_DATE)}}
            </div>
            <div>
                {{removeDate(leg.SCHED_DEP_DATE_OFF)}}
            </div>
            <div>
                {{leg.LINEABBREVIATION}}
                #{{leg.TRAIN_ID}}
            </div>
            <div>
                Track {{leg.CURRENT_TRACK}} {{leg.STATUS}}
            </div>
        </div>
        <div v-else class="content">
            <div>
                {{removeDate(leg.SCHED_DEP_DATE)}}
            </div>
            <div class="indicator-box">
                <div v-if="isFirst" class="indicator first" :style="indicatorBorderStyle"></div>
                <div v-else class="indicator" :style="baseIndicatorStyle"></div>
                {{leg.STATION_NAME_ON}}
            </div>
            <div>
                {{leg.LINEABBREVIATION}}
                #{{leg.TRAIN_ID}}
            </div>
            <div>
                {{removeDate(leg.SCHED_DEP_DATE_OFF)}}
            </div>
            <div class="indicator-box">
                <div v-if="isLast" class="indicator last" :style="indicatorBorderStyle"></div>
                <div v-else class="indicator" :style="baseIndicatorStyle"></div>
                {{leg.STATION_NAME_OFF}}
            </div>
            <div>
                Track {{leg.CURRENT_TRACK}} {{leg.STATUS}}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "StationToStationDisplay",
        props: ["leg", "isFirst", "isLast"],
        data() {
            return {
                style: "",
                baseIndicatorStyle: "",
                indicatorBorderStyle: "",
                iconStyle: ""
            }
        },
        mounted() {
            // set the style for foreground & background
            this.style = `background-color: ${this.$props.leg.BACKCOLOR}; color: ${this.$props.leg.FORECOLOR}`;
            this.baseIndicatorStyle = `background-color: ${this.$props.leg.FORECOLOR};`;
            this.indicatorBorderStyle = `border: 1px solid ${this.$props.leg.FORECOLOR}`;

            this.iconStyle = `background-image: url(./line_icons/${this.$props.leg.LINECODE}_icon.png);
                                background-size: contain;
                                background-position: center center;`
        },
        methods: {
            removeDate(date){
                const firstSpace = date.indexOf(" ");
                return date.substring(firstSpace);
            },
            gotoStops(){
                this.$emit("navigate-to-stops", this.$props.leg.TRAIN_ID);
            }
        }
    }
</script>

<style scoped>
    .leg {
        display: grid;
        grid-template-columns: 1fr 8fr;
        /* border-radius: 3rem; */
        margin: 0.5rem 1rem;
        border: 1px solid black;
        border-radius: calc(1.5 * var(--mvh));

        cursor: pointer;
    }

    .mobile .leg {
        border-radius: 1rem;
    } 

    .icon {
        display: flex;
        height: 100%;
        align-items: center;
        width: 4rem;
        margin-left: 0.2rem;
    }

    .mobile .icon {
        margin-left: 0.5rem;
    }

    .content {
        display: grid;
        grid-template-columns: 2fr 3fr 2fr;
        grid-gap: 0.5rem;
        padding: 0.5rem;
    }

    .mobile .content {
        grid-template-columns: 1fr 1fr;
        margin-left: 1rem;
    }

    .content * {
        font-size: 1.2rem;
    }

    .indicator-box {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 1rem;
    }

    .indicator {
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
    }

    .first {
        background-color: green;
    }

    .last {
        background-color: red;
    }
</style>