
Displays an individual trip

Clicking on this element will navigate to the list of stops for this trip

v-if="($mq === 'large' || query.layout === 'l') && query.layout !== 'm'"

<template>
        <div 
            v-if="$isMobile && pageType !== 'rail'" 
            :class="'portrait data trip-card ' + pageType" 
            :style="dataStyle"
            @click="navigateToStops"
            ref="trip"
        >
            <div>
                <div 
                    v-if="icon" 
                    class="route-number rail-icon"
                    :style="iconStyle"
                >
                </div>
                <div v-else :class="'route-number' + (routeSplit.length > 1 ? ' stacked' : '')">
                    <span v-for="(item, index) in routeSplit"
                        :key="index"
                    >
                        {{item}}
                    </span>
                </div>
                <div class="data-element">
                    <span v-if="$props.trip.busid">Bus # </span> 
                    {{$props.trip.busid}}
                </div>
            </div>
            <div class="main">
                <div class="bold dest spaced">
                    <span>
                        {{header}}
                    </span>
                </div>
                <div :style="`display: grid; grid-template-columns: 3fr 4fr; grid-gap: calc(1 * var(--mvh)); justify-items: flex-start; width: 100%; padding-left: ${pageType === 'rail' ? 7 : 0}vw;`">                    
                    <div class="data-element time">
                        {{getTime($props.trip.sched_dep_time)}}
                    </div>
                    <div class="data-element bold" style="justify-self: center;">
                        {{
                            ($props.trip.departuretime !== "Approaching" && !isPABT())
                            ? ($props.trip.departuretime.indexOf("in") > -1) 
                                ? "Arrives" 
                                :  ((pageType === 'lightrail') 
                                    ? "Departing at" 
                                    : "Arriving at")
                            : isPABT() ? "Departing at" : ""
                        }}
                        {{isPABT() ? getTime($props.trip.sched_dep_time) :  ($props.trip.departuretime.trim() || " ")}}
                    </div>
                    <div>
                        {{ laneGateLabel }}
                        <span :class="'data-element gate' + laneGateClass()">{{ laneGateFilter() }}</span>
                    </div>
                    <PassengerDensity v-if="!isPABT()" :level="$props.trip.passload" horizontal="true" />
                </div>
            </div>
            <div v-if="$props.trip.message" class="trip-message">
                &#9733; {{$props.trip.message}}
            </div>
        </div>



        <div 
            v-else-if="$isMobile && pageType === 'rail'" 
            class="portrait data trip-card" 
            :style="dataStyle"
            @click="navigateToStops"
            ref="trip"
        >
            <div style="padding-top: calc(0.5 * var(--mvh));">
                <div 
                    v-if="icon" 
                    class="route-number rail-icon"
                    :style="iconStyle"
                >
                </div>
                <div v-else :class="'route-number' + (routeSplit.length > 1 ? ' stacked' : '')">
                    <span v-for="(item, index) in routeSplit"
                        :key="index"
                    >
                        {{item}}
                    </span>
                </div>
                <span class="line-abbr">
                    {{$props.trip.line_abbr}}
                </span>
            </div>
            <div class="main">
                <div style="display: flex; flex-direction: column; width: 100%; height: 100%; justify-content: space-evenly; gap: calc(0.8 * var(--mvh));">
                    <div class="rail-row">
                        <span class="bold dest" style="position: relative;">
                            <div style="position: absolute; left: -1.4rem; top: 50%; transform: translateY(-40%);">
                            <RightArrow />
                            </div>
                            <div>
                                {{header}}
                            </div>
                        </span>
                        <div class="data-element time" style="margin-right: 0;">
                            {{getTime($props.trip.sched_dep_time)}}
                        </div>
                    </div>

                    <div class="rail-row">
                        <div>
                            {{ laneGateLabel }}
                            <span :class="'data-element gate' + laneGateClass()">{{ laneGateFilter() }}</span>
                        </div>

                        <div class="data-element bold" style="font-size: 0.95rem;">
                            {{$props.trip.departuretime.trim() || " "}}
                        </div>
                    </div>

                    <div class="rail-row">
                        <div class="data-element" style="font-size: 0.95rem;">
                            #  {{$props.trip.busid}}
                        </div>
                        <div style="display: flex; align-items: center; justify-content: space-between; justify-self: center;">
                            <RailPassengerDensity 
                                v-if="mode !== 'expandedCard'"
                                :horizontal="true"
                                :cars="$props.trip.passload"  
                                @open-expanded-card="openExpandedCard"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="$props.trip.message" class="trip-message">
                &#9733; {{$props.trip.message}}
            </div>
        </div>



        <div v-else-if="($props.query.shorten !== false && pageType === 'rail' && !$isSignMode) || ($props.query.shorten === 'true' && pageType === 'rail' && $isSignMode)"
            class="landscape data trip-card rail-shortened" 
            :style="dataStyle"
            @click="navigateToStops"
            ref="trip"
        >
            <div>
                <div 
                    v-if="icon" 
                    class="route-number rail-icon"
                    :style="iconStyle"
                >
                </div>
                <div v-else :class="'route-number' + (routeSplit.length > 1 ? ' stacked' : '')">
                    <span v-for="(item, index) in routeSplit"
                        :key="index"
                    >
                        {{item}}
                    </span>
                </div>
            </div>
            <div class="main">
                <div class="inner-shortened-first">
                    <div class="bold dest" :style="`position: relative; margin-left: ${$isTablet ? '6vw' : '4vw'};`">
                        <span v-if="$props.isRail" style="position: absolute; top: 50%; left: -2.3rem; transform: translateY(calc(-50% + 0.1rem));">
                            <RightArrow />
                        </span>
                        <div>
                            {{header}}
                        </div>
                    </div>
                    
                    <div class="data-element inner-linecode">
                        {{ /* Line */ }}
                        <span v-if="$props.isRail">{{$props.trip.line_fullname}}</span>
                    </div>
                    
                    <div class="data-element inner-arrival bold">
                        {{
                            ($props.trip.departuretime !== "Approaching" && !$props.isRail && !isPABT())
                            ? ($props.trip.departuretime.indexOf("in") > -1) 
                                ? "Arrives" 
                                :  ((pageType === 'lightrail') 
                                    ? "Departing at" 
                                    : "Arriving at")
                            : isPABT() ? "Departing at" : ""
                        }}
                        <br />
                        {{isPABT() ? getTime($props.trip.sched_dep_time) : (getTime($props.trip.departuretime).trim() || " ")}}
                    </div>
                </div>
                <div class="inner-shortened-second">
                    <div class="data-element inner-busid">
                        <span v-if="$props.isRail"># </span> 
                        <span v-else-if="$props.trip.busid">Bus # </span> 
                        {{$props.trip.busid}}
                    </div>

                    <div class="time">
                        {{getTime($props.trip.sched_dep_time)}}
                    </div>

                    <div class="data-element inner-gate">
                        {{ laneGateLabel }} 
                        <span :class="'data-element gate' + laneGateClass()">{{ laneGateFilter() }}</span>
                    </div>

                    <RailPassengerDensity 
                        v-if="$props.isRail && mode !== 'expandedCard'" 
                        :cars="$props.trip.passload"  
                        @open-expanded-card="openExpandedCard"
                    />
                </div>
            </div>
            <div v-if="$props.trip.message">
            </div>
            <div v-if="$props.trip.message" class="trip-message">
                &#9733; {{$props.trip.message}}
            </div>
        </div>



        <div v-else-if="($props.query.shorten !== false && !$isSignMode) || ($props.query.shorten === 'true' && $isSignMode)"
            :class="'landscape data trip-card nonrail-shortened ' + pageType" 
            :style="dataStyle"
            @click="navigateToStops"
            ref="trip"
        >
            <div>
                <div 
                    v-if="icon" 
                    class="route-number rail-icon"
                    :style="iconStyle"
                >
                </div>
                <div v-else :class="'route-number' + (routeSplit.length > 1 ? ' stacked' : '')">
                    <span v-for="(item, index) in routeSplit"
                        :key="index"
                    >
                        {{item}}
                    </span>
                </div>
            </div>
            <div class="main">
                <div class="bold dest" style="position: relative; margin-left: 4vw;">
                    <span v-if="$props.isRail" style="position: absolute; top: 50%; left: -2.3rem; transform: translateY(calc(-50% + 0.1rem));">
                        <RightArrow />
                    </span>
                    <div>
                        {{header}}
                    </div>
                </div>
                <div class="inner-landscape-grid">
                    <div class="data-element inner-busid">
                        <span v-if="$props.isRail"># </span> 
                        <span v-else-if="$props.trip.busid">Bus # </span> 
                        {{$props.trip.busid}}
                    </div>

                    <div class="data-element inner-linecode">
                        {{ /* Line */ }}
                        <span v-if="$props.isRail">{{$props.trip.line_fullname}}</span>
                    </div>

                    <div class="time">
                        {{getTime($props.trip.sched_dep_time)}}
                    </div>

                    <div class="data-element inner-gate">
                        {{ laneGateLabel }} 
                    <span :class="'data-element gate' + laneGateClass()">{{ laneGateFilter() }}</span></div>
                </div>
            </div>
            <div>
                <div v-if="!$isDisconnected" class="data-element inner-arrival bold">
                    {{
                        ($props.trip.departuretime !== "Approaching" && !$props.isRail && !isPABT())
                        ? ($props.trip.departuretime.indexOf("in") > -1) 
                            ? "Arrives" 
                            :  ((pageType === 'lightrail') 
                                ? "Departing at" 
                                : "Arriving at")
                        : isPABT() ? "Departing at" : ""
                    }}
                    <br />
                    {{isPABT() ? getTime($props.trip.sched_dep_time) : ($props.trip.departuretime.trim() || " ")}}
                </div>
                <RailPassengerDensity 
                    v-if="$props.isRail && !$isDisconnected && mode !== 'expandedCard'" 
                    :cars="$props.trip.passload"  
                    @open-expanded-card="openExpandedCard"
                />
                <PassengerDensity v-else-if="!isPABT() && !$isDisconnected" :level="$props.trip.passload" />
            </div>
            <div v-if="$props.trip.message">
            </div>
            <div v-if="$props.trip.message" class="trip-message">
                &#9733; {{$props.trip.message}}
            </div>
        </div>



        <div 
            v-else
            class="landscape data trip-card" 
            :style="dataStyle"
            @click="navigateToStops"
            ref="trip"
        >
            <div>
                <div 
                    v-if="icon" 
                    class="route-number rail-icon"
                    :style="iconStyle"
                >
                </div>
                <div v-else :class="'route-number' + (routeSplit.length > 1 ? ' stacked' : '')">
                    <span v-for="(item, index) in routeSplit"
                        :key="index"
                    >
                        {{item}}
                    </span>
                </div>
            </div>
            <div class="main">
                <div class="bold dest" style="position: relative; margin-left: 4vw;">
                    <span v-if="$props.isRail" style="position: absolute; top: 50%; left: -2.3rem; transform: translateY(calc(-50% + 0.1rem));">
                        <RightArrow />
                    </span>
                    <div>
                        {{header}}
                    </div>
                </div>
                <div class="inner-landscape-grid">
                    <div class="data-element inner-busid">
                        <span v-if="$props.isRail"># </span> 
                        <span v-else-if="$props.trip.busid">Bus # </span> 
                        {{$props.trip.busid}}
                    </div>

                    <div class="data-element inner-linecode">
                        {{ /* Line */ }}
                        <span v-if="$props.isRail">{{$props.trip.line_fullname}}</span>
                    </div>

                    <div class="time">
                        {{getTime($props.trip.sched_dep_time)}}
                    </div>

                    <div class="data-element inner-gate">
                        {{ laneGateLabel }}
                    <span :class="'data-element gate' + laneGateClass()">{{ laneGateFilter() }}</span></div>
                </div>
            </div>
            <div>
                <div v-if="!$isDisconnected" class="data-element inner-arrival bold">
                    {{
                        ($props.trip.departuretime !== "Approaching" && !$props.isRail && !isPABT())
                        ? ($props.trip.departuretime.indexOf("in") > -1) 
                            ? "Arrives" 
                            :  ((pageType === 'lightrail') 
                                ? "Departing at" 
                                : "Arriving at")
                        : isPABT() ? "Departing at" : ""
                    }}
                    {{isPABT() ? getTime($props.trip.sched_dep_time) : ($props.trip.departuretime.trim() || " ")}}
                </div>
                <RailPassengerDensity 
                    v-if="$props.isRail && !$isDisconnected && mode !== 'expandedCard'" 
                    :cars="$props.trip.passload"  
                    @open-expanded-card="openExpandedCard"
                />
                <PassengerDensity v-else-if="!isPABT() && !$isDisconnected" :level="$props.trip.passload" />
            </div>
            <div v-if="$props.trip.message">
            </div>
            <div v-if="$props.trip.message" class="trip-message">
                &#9733; {{$props.trip.message}}
            </div>
        </div>
</template>

SCHEMA EXAMPLE: 

departuretime: (...)
fullscreen: (...)
header: (...)
internal_trip_number: (...)
lanegate: (...)
message: (...)
public_route: (...)
remarks: (...)
sched_dep_time: (...)
timing_point_id: (...)

// potential additional values for RAIL data

BACKCOLOR: (...),
FORECOLOR: (...),
icon: (...)

<script>
    import PassengerDensity from "@/data_display/PassengerDensity";
    import RailPassengerDensity from "@/data_display/RailPassengerDensity";
    import { encryptTrip, getTimeFromDatetime, insertPlane } from "@/Utilities";
    import RightArrow from "@/components/RightArrow";

    export default {
        name: "TripDisplay",
        props: ["trip", "query", "isRail", "dataIndex", "mode", "mobileCard", "tabletCard", "rotatedMobile"],
        components: {
            PassengerDensity,
            RailPassengerDensity,
            RightArrow
        },
        watch: {
            mobileCard: function(oldval, newval){
                console.log(oldval, newval);
                this.setDataStyle();
            },
            tabletCard: function(oldval, newval){
                console.log(oldval, newval);
                this.setDataStyle();
            },
            rotatedMobile: function(oldval, newval){
                console.log(oldval, newval);
                this.setDataStyle();
            }
        },
        data() {
            return {
                BACKCOLOR: this.$props.trip.BACKCOLOR,
                FORECOLOR: this.$props.trip.FORECOLOR,
                dataStyle: "",
                icon: this.$props.trip.icon,
                iconStyle: this.$props.trip.icon
                    ? `background-image: url(${this.$props.trip.icon}); background-size: contain; background-position: center;`
                    : "",
                pageType: "",
                routeSplit: [],
                header: "",
                listener: null,
                vehicle_id: this.$props.trip.busid,
                laneGateLabel: "",
                isExpandedOpen: false
            };
        },
        created() {
            const pageType = this.$route.path.split("/")[1];

            console.log("START TRIP DISPLAY", this.vehicle_id);

            console.log("WHAT IS THE PAGETYPE HERE", pageType);

            if(this.$props.trip.BACKCOLOR){
                this.dataStyle = `background-color: ${this.$props.trip.BACKCOLOR}; color: ${this.$props.trip.FORECOLOR};`;
            }
            else if(pageType === "lightrail"){
                if(this.dataIndex % 2 === 0) {
                    this.dataStyle = "background-color: #d4b2f0; color: black;";
                    // this.dataStyle = "background-color: #e7d8f3; color: black";
                }
                else{
                    this.dataStyle = "background-color: #f4edfb; color: black;";
                }
                //cca5ed
            }
            
            if(this.$isMobile && pageType === "rail"){
                this.dataStyle += " padding: calc(0.5 * var(--mvh)) 0;";
            }

            this.setDataStyle();

            this.pageType = pageType;

            this.header = insertPlane(this.$props.trip.header.trim());

            console.log("HEADER VALUE", this.header);

            const pub = this.$props.trip.public_route;
            if(pub == 343){
                this.routeSplit = ["RL"];
            }
            else if(pub == 342){
                this.routeSplit = ["HB"];
            }
            else if(pub == 7){
                this.routeSplit = ["NLR"];
            }
            else{
                this.routeSplit = this.$props.trip.public_route.split(" ");
            }

            console.log("LANE GATE CHECK", this.$props, this.$props.trip.lanegate);
            if(this.$props.trip.lanegate 
                && this.$props.trip.lanegate.toUpperCase() !== "EMPTY" 
                && this.$props.trip.lanegate.toUpperCase() !== ".."
            ){
                if(this.$props.isRail || this.pageType === 'lightrail'){
                    this.laneGateLabel = "TRACK: ";
                }
                else{
                    this.laneGateLabel = "GATE: ";
                }
            }

        },
        mounted() {
            console.log("CAN WE SET THE LISTENER?");
            this.setListener();
        },
        destroyed() {
            this.removeListener();
            // this.removeTextListener();
        },
        methods: {
            isPABT(){
                return this.$props.query.stop === "PABT" 
                    || this.$props.query.stop == "31858" 
                    || this.$props.query.stopid == "31858"
                    || this.$props.query.stop == "26229" 
                    || this.$props.query.stopid == "26229";
            },
            openExpandedCard() {
                // alert("OPENING FROM THE TRIP DISPLAY");
                this.isExpandedOpen = true;

                this.$emit("open-expanded-trip-card", this.trip, this.dataIndex, this.closeExpandedCard);
            },
            closeExpandedCard() {
                alert("CLOSING FROM THE TRIP DISPLAY");
                this.isExpandedOpen = false;
            },
            setDataStyle() {
                if(this.$props.mode === "expandedCard" && this.$props.rotatedMobile){
                    this.dataStyle += " width: 100%; transform: scale(0.82) translate(-0.5rem, -0.5rem); margin-bottom: 1.5rem;";
                }
                else if(this.$props.mode === "expandedCard" && !this.$props.mobileCard) {
                    this.dataStyle += " width: 100%; transform: scale(0.9) translate(-0.5rem, 0rem); margin-bottom: 1rem; ";
                }
                else if(this.$props.mode === "expandedCard" && this.$props.mobileCard && !this.$props.tabletCard) {
                    this.dataStyle += `                
                        width: calc(100% + 2.1rem);
                        transform: translate(-1.05rem, -20%);
                        box-shadow: none;
                        border: none;
                        border-bottom-left-radius: 0;
                        border-bottom-right-radius: 0;
                    `;
                }
                else if(this.$props.mode === "expandedCard" && this.$props.mobileCard && this.$props.tabletCard) {
                    this.dataStyle += `                
                        width: calc(100% + 2.05rem);
                        transform: translate(-0.7rem, -5%);
                        box-shadow: none;
                        border: none;
                        border-bottom-left-radius: 0;
                        border-bottom-right-radius: 0;
                    `;
                }
            },
            laneGateClass() {
                if(this.$props.trip.laneChanged){
                    if(this.$props.trip.laneJustChanged){
                        return " lane-changed";
                    }
                    else{
                        return " lane-changed lane-changed-now";
                    }
                }

                return "";
            },
            laneGateFilter() {
                if(
                    this.$props.trip.lanegate.trim().toUpperCase() === "EMPTY"
                    || this.$props.trip.lanegate.trim() === ".."
                ){
                    return "";
                }
                return this.$props.trip.lanegate;
            },
            navigateToStops(){

                if(this.$props.mode === "expandedCard"){
                    return;
                }

                // alert("CAR NOT EXPANDED!");

                // let v = false;

                // if(!v) {
                //     return;
                // }

                setTimeout(() => {
                    // const params = {
                    //     internal_trip_number: this.$props.trip.internal_trip_number,
                    //     sched_dep_time: this.$props.trip.sched_dep_time,
                    //     timing_point_id: this.$props.trip.timing_point_id,
                    //     route: this.$props.trip.public_route || this.$props.trip.linecode,
                    //     iconStyle: this.iconStyle,
                    //     vehicle_id: this.vehicle_id
                    // };

                    const params = {
                        VehicleInternalTripNumber: this.$props.trip.internal_trip_number,
                        VehicleScheduledDeparture: this.$props.trip.sched_dep_time,
                        timing_point_id: this.$props.trip.timing_point_id,
                        VehicleRoute: this.$props.trip.public_route || this.$props.trip.linecode,
                        iconStyle: this.iconStyle,
                        VehicleID: this.$props.trip.vehicle_id
                    };

                        //     a: trip.VehicleInternalTripNumber,
    //     b: trip.VehicleScheduledDeparture,
    //     c: trip.VehicleRoute,
    //     d: trip.

                    console.log("INVALID STARTING POINT?", params);

                    // the only way to pas all of the information necessary for getting
                    // the stops and maintaining proper future navigation to the 
                    // "all trips" route is to have all of the above passed through
                    // - to make this part of the URL, this object will be encrypted into a string
                    //   and passed along in the querystring
                    const encrypted = this.$props.isRail ? params.VehicleInternalTripNumber : encryptTrip(params);

                    console.log("NAVIGATE TO STOPS ROUTE", encrypted, encrypted.length);

                    this.$emit("navigate-to-stops", encrypted);
                }, 200);
            },
            getTime(dt){ // formatting for display
                return getTimeFromDatetime(dt);
            },
            getImgUrl(pic) {
                // https://stackoverflow.com/questions/40491506/vue-js-dynamic-images-not-working/47480286#47480286
                return require(pic);
            },
            setListener(){
                console.log("LISTENER SETUP ATTEMPT");

                let set = false;
                const func = (evt) => {
                    console.log("TRIP CLICK LISTENER", evt);
                    
                    if(!set){
                        const rect = this.$refs.trip.getBoundingClientRect();

                        console.log("RECT", rect);

                        const bounds = document.createElement("div");
                        bounds.style.borderRadius = "calc(1.5 * var(--mvh))";
                        bounds.style.zIndex = "100";
                        bounds.style.backgroundColor = "transparent";
                        bounds.style.height = rect.height + "px";
                        bounds.style.width = rect.width + "px";
                        bounds.style.overflow = "hidden";
                        bounds.style.position = "absolute";

                        if(this.$props.mode !== "expandedCard"){
                            const circle = document.createElement("div");
                            circle.classList.add("circle");
    
                            circle.style.width = "50px";
                            circle.style.height = "50px";
    
                            if(this.$props.trip.BACKCOLOR === "black"){
                                console.log("CIRCLE LIGHT");
                                circle.style.backgroundColor = "white";
                            }
    
                            circle.style.left = evt.clientX - rect.left + "px";
                            circle.style.top = evt.clientY - rect.top + "px";
    
                            // document.appendChild(circle);
                            bounds.appendChild(circle);
                            this.$refs.trip.appendChild(bounds);
    
                            setTimeout(() => {
                                circle.remove();
                                set = false;
                            }, 300);
                        }
                    }
                    set = true;
                }
                this.listener = func;

                console.log("LISTENER IS POSSIBLE?", this.$refs);

                this.$refs.trip.addEventListener("click", func);
            },
            removeListener(){
                if(this.listener && this.$refs.trip){
                    this.$refs.trip.removeEventListener("click", this.listener);
                }
                this.listener = null;
            },
        }
    }
</script>

<style scoped>

    .data {
        box-shadow: 0px 4px 3px -2px rgb(0 0 0 / 25%), 
                    0px 3px 3px 0px rgb(0 0 0 / 19%), 
                    0px 2px 6px 0px rgb(0 0 0 / 16%),
                    2px 0px 2px 0px rgb(0 0 0 / 10%);
        border-top: 1px solid var(--primary-color);

        position: relative;
        /* overflow: hidden; */
    }

    .trip-card {
        cursor: pointer;
    }

    .trip-card:nth-child(2n+1) {
        background-color: #e4edf9;
    }

    .trip-card:nth-child(2n+1):hover {
        /* background-color: #ddd; */
    }

    .trip-card:hover {
        opacity: 0.9;
    }

    .portrait {
        display: grid;
        grid-template-columns: 3fr 13fr;
        padding: 1vw;
        margin: calc(0.4 * var(--mvh)) 0vw;
        width: 97%;
        /* margin: 0.6vh 3vw;
        width: 94%; */
        border: 1px solid black;
        border-radius: 2vw;
        color: #222;
    }

    .landscape {
        display: grid;
        grid-template-columns: 1fr 8fr 3fr;
        padding: 1.1vw;
        margin: calc(0.9 * var(--mvh)) 0.9vw;
        width: 98%;
        border: 1px solid black;
        /* border-radius: 1vw; */
        font-size: 1.2rem;
        color: #222;
        border-radius: calc(1.5 * var(--mvh));
    }

    .very-large-screen .landscape {
        grid-template-columns: 1fr 10fr 5fr;
    }
    
    .very-large-screen .landscape {
        margin: calc(0.5 * var(--mvh)) 0.9vw;
        border-radius: calc(1.0 * var(--mvh));
    }

    .in-station .landscape {
        margin: calc(0.4 * var(--mvh)) 0.9vw;
        border-radius: calc(1.1 * var(--mvh));
    }

    .landscape.rail-shortened {
        grid-template-columns: 1fr 11fr;
        padding: 0.3vw;
        transform: scale(0.8);
        width: 123%;
        margin: -0.3rem;
    }

    .landscape.nonrail-shortened {
        display: grid;
        padding: 0.3vw;
        /* margin: -0.3rem; */
        margin: 0.4rem;
        width: 123%;
        font-size: 1rem;
        /* transform: scale(0.8); */
    }

    .lightrail.landscape.nonrail-shortened {
        margin: -0.3rem;
        transform: scale(0.8);
    }

    .tablet .landscape.rail-shortened,
    .tablet .landscape.nonrail-shortened {
        padding: 0.8vw 0.8vw 0.8vw 1rem;
    }

    .tablet .landscape.nonrail-shortened * {
        /* transform: scale(0.9); */
    }

    .landscape .data-element,
    .landscape .data-element * {
        font-size: 1.5rem;
    }

    .tablet .landscape .data-element,
    .tablet .landscape .data-element * {
        font-size: 1.2rem;
        align-self: center;
    }

    .portrait .route-number {
        width: 14.5vw;
        height: 14.5vw;
        padding: 5px;
        border-radius: 50%;
        border: 2px solid white;
    }

    .landscape .route-number {
        width: 6.5vw;
        height: 6.5vw;
        padding: 5px;
        border-radius: 50%;
        border: 2px solid white;
    }

    .very-large-screen .landscape .route-number {
        width: 7.1vw;
        height: 7.1vw;
    }

    .in-station .route-number,
    .in-station .landscape .route-number {
        width: 8vw;
        height: 8vw;
    }

    .rotated .landscape .route-number {
        width: 7.5vw;
        height: 7.5vw;
    }

    .route-number {
        background-color: var(--primary-color);
        border-radius: 50%;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .portrait .route-number * {
        font-size: 1.3rem;
        font-weight: 500;
    }
    
    .landscape .route-number * {        
        font-size: min(2.2rem, 2vw);
        font-weight: 500;
    }

    .tablet .route-number * {
        font-size: 1.5rem;
    }

    .portrait .route-number.stacked * {
        font-size: 1.15rem;
    }

    .landscape .route-number.stacked * {
        font-size: max(1.5rem, 1.5vw);
    }

    .landscape.nonrail-shortened .route-number, 
    .landscape.rail-shortened .route-number {
        width: 5rem;
        height: 5rem;
    }

    .route-number.rail-icon {
        background-color: transparent;
    }

    .portrait .gate {
        font-weight: bold;
        font-size: 1rem;
    }

    .landscape .gate {
        font-weight: bold;
        margin-left: 0.1rem;
    }

    .portrait > div, .landscape > div {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .inner-landscape-grid {
        display: grid;
        grid-template-areas:
            "busid linecode"
            "arrival gate";
        grid-template-columns: 1fr 1fr;
        gap: 0.5rem;
        width: 100%;
        margin-top: 0.5rem;
        margin-left: 4vw;
    }

    .inner-shortened-first {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr;
        width: 100%;
    }

    .inner-shortened-first .inner-linecode, 
    .inner-shortened-first .inner-arrival {
        grid-area: initial;
    }

    .inner-shortened-first .dest {
        justify-content: initial;
    }

    .inner-shortened-second {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        width: 100%;
        margin-top: 0.5rem;
        /* margin-left: 4vw; */
    }

    .inner-shortened-second .inner-gate, 
    .inner-shortened-second .inner-busid {
        grid-area:initial;
    }

    .inner-shortened-second .inner-busid {
        margin-left: 4vw;
    }

    .main {
        margin-left: 1vw;
    }

    .main > * {
        align-self: start;
    }

    .portrait .main {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-left: 1vw;
    }

    .portrait .main .spaced {
        margin-bottom: calc(1 * var(--mvh));
    }

    .inner-busid {
        grid-area: busid
    }

    .inner-linecode {
        grid-area: linecode;
    }

    .inner-arrival {
        grid-area: arrival;
        font-size: 1.5rem;
        text-align: center;
    }

    .inner-gate {
        grid-area: gate;
        align-self: center;
    }
    
    .bold {
        font-weight: bold;
    }

    .dest {
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }

    .rail-row {
        width: 100%;
        padding-left: 5vw;
        padding-right: 5vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .landscape .dest div {
        /* font-size: 2.3rem; */
        font-size: 2.0rem;
    }

    .tablet .landscape .dest div {
        /* font-size: 2.3rem; */
        font-size: 1.7rem;
    }

    .very-large-screen .landscape .dest div {
        font-size: 1.9rem;
    }

    .mobile .dest span:first-of-type {
        font-size: 1.2rem;
    }

    .dest svg {
        transform-origin: center;
        transform: scale(1.4) translateY(-0%);
        margin-right: 2vw;
    }

    .landscape .dest svg {
        transform: scale(2) translateX(20%);
        margin-right: 2vw;
    }

    .line-abbr {
        font-size: 0.7rem;
        text-align: left;
        margin: 4px 0;
        letter-spacing: 1px;
    }

    .portrait.data .dest {
        font-size: 1.2rem;
        /* width: 90%;
        text-align: center; */
        letter-spacing: 0.02rem;
    }

    .time {
        position: relative;
        width: fit-content;
    }

    .landscape .clock-icon {
        position: absolute;
        left: calc(100% + 0.75vw);
        top: -0.2rem;
        height: 100%;
        font-size: 1.8rem;
    }

    .portrait .clock-icon {
        position: absolute;
        left: calc(100% + 2vw);
        top: -70%;
        height: 100%;
        font-size: 1.8rem;
    }

    .landscape .trip-message {
        justify-self: flex-start;
        margin: calc(2 * var(--mvh)) 0 0 1vw;
        font-size: 1.5rem;
        font-weight: 500;
    }

    .shortened.landscape .trip-message {
        margin: calc(0.5 * var(--mvh)) 0 0 1vw;
    }

    .rail-shortened.landscape .trip-message {
        margin: calc(0.5 * var(--mvh)) 0 0 1vw;
    }

    .portrait .trip-message {
        text-align: center;
        width: 100%;
        margin-top: calc(2 * var(--mvh));
        font-size: 1.2rem;
        font-weight: 500;
        grid-column: 1 / -1;
    }

    /*
        SELECTION "BURST" ANIMATION
    */

    .circle {
        border-radius: 50%;
        position: absolute;
        z-index: 3;

        background-color: black;        
        opacity: 0.3;

        animation: expand 0.3s ease-out forwards;
    }

    @keyframes expand {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(500);
            opacity: 1;
        }
    }

    .lane-changed {
        color: rgb(212, 22, 22);
        transform: scale(1.1) translateX(0.2rem);
        position: relative;
        /* text-shadow: 0 0 1px rgba(0,0,0,0.5); */
        display: inline-block;
        background-color: rgba(255,255,255, 0.85);
        border-radius: 0.65rem;
        padding: 0.05rem 0.5rem 0.05rem 0.5rem;
        border: 1px solid #ddd;
        text-align: center;
        margin-left: 0.15rem;
    }

    /* .lane-changed::after {
        position: absolute;
        content: "";
        background: currentColor;
        height: 0.25rem;
        width: 0.25rem;
        border-radius: 50%;
        top: 0.3rem; 
        right: -0.25rem;
    } */
    
    /* .lane-changed-now {
        animation: lane-pulse 2s;
    }

    @keyframes lane-pulse {
        0% {
            transform: scale(1.0);
        }
        60% {
            transform: scale(1.3);
        }
        100% {
            transform: scale(1.2);
        }
    } */
</style>