
Displays an individual stop within a trip, a fairly straightforward component

Currently the bell/alert functionality is not implemented due to 
questions of whether it will be included due to issues with iOS

($mq === 'mobile' || query.layout === 'm') && query.layout !== 'l'

<template>
    <div 
        :class="'data stop' + ($isMobile ? ' mobile' : '') + (trip.FORECOLOR ? ' forecolor' : '') + (pageType === 'rail' ? ' rail' : ' not-rail')"
        :style="
            (this.foreColor && pageType === 'rail')
            ? `
                color: ${this.foreColor}; 
                background-color: ${this.backColor};
                ${$isMobile ? 'padding: calc(2 * var(--mvh)) calc(1 * var(--mvh))' : ($isRotatedMobile ? '' :  'padding: calc(2 * var(--mvh)) calc(2 * var(--mvh)) calc(2 * var(--mvh)) calc(1 * var(--mvh))')};
            `
            : (this.foreColor)
                ?   `color: ${this.foreColor}; 
                    background-color: ${this.backColor};
                    `
                : ''
        "
    >
        <div
            v-if="$isTabletForm"
            class="portrait"
        >
            <div class="stop-first">
                <div :class="nodeContainerClass">
                    <div class="node node-mobile"></div>
                </div>
                <div v-if="description !== ''" class="name">
                    {{description}}
                </div>
                <div v-else class="name">
                    {{$props.stop.TimePoint}}
                </div>
            </div>
            <div class="stop-last">
                <div v-if="isDeparted && !isEnd" class="departed" style="font-weight: bold">
                    DEPARTED
                </div>
                <div v-else-if="isCancelled" style="font-weight: bold">
                    Cancelled
                </div>
                <div v-else-if="isOtherCanceled" style="font-weight: bold">
                    CANCELED
                </div>
                <div v-else-if="time" :class="'time' + (isApproxTime ? ' approx' : '')">
                    <AlertBell v-if="!isDeparted && !tooSoonForAlert && pageType === 'rail'" :stop="stop" :trip="trip" :stationCode="stationCode" @set-alert-processing="setAlertProcessing" />
                    {{time}}
                </div>
                <div v-else>
                    <!-- placeholder -->
                </div>
                <!-- <div :class="hasTransfers ? 'has-transfers': ''">
                    <div v-if="isDeparted" class="node node-mobile departed-node">
                    </div>
                    <div v-else-if="$props.stop.isStart" class="node node-mobile start-node">
                    </div>
                    <div v-else-if="$props.stop.isEnd" class="node node-mobile end-node">
                    </div>
                    <div v-else class="node node-mobile">
                    </div>
                </div> -->
            </div>
            <div v-if="hasTransfers" class="change-for">
                <div
                    v-bind:key="transfer.route" 
                    v-for="transfer in $props.stop.transfers" 
                    v-bind:transfer="transfer" 
                >
                    <img v-if="pageType === 'rail'" 
                        :class="'route-number rail-route'"
                        :src="`/line_icons/${transfer.route}_icon.png`"
                    />
                    <div v-else class="route-number">
                        {{transfer.route}}
                    </div>
                </div>
            </div>

            <!-- <div class="change-for-mobile">
                <div>Change For:</div>
                <div>CHANGE ICONS HERE</div>
            </div> -->
        </div>
        <div v-else :class="'landscape' + ((hasTransfers || pageType === 'rail') ? '' : ' no-transfer')">
            <div class="stop-first">
                <div :class="nodeContainerClass">
                    <div class="node"></div>
                </div>
                <div v-if="description !== ''" class="name">
                    {{description}}
                </div>
                <div v-else class="name">
                    {{$props.stop.TimePoint}}
                </div>
            </div>
            <div v-if="hasTransfers || pageType === 'rail'" class="change-for">
                <div
                    v-bind:key="transfer.route" 
                    v-for="transfer in $props.stop.transfers" 
                    v-bind:transfer="transfer" 
                >
                    <img v-if="pageType === 'rail'" 
                        :class="'route-number rail-route'"
                        :src="`/line_icons/${transfer.route}_icon.png`"
                    />
                    <div v-else class="route-number">
                        {{transfer.route}}
                    </div>
                </div>
            </div>
            <div class="stop-last">
                <div v-if="isDeparted && !isEnd" style="font-weight: bold">
                    DEPARTED
                </div>
                <div v-else-if="isCancelled" style="font-weight: bold">
                    Cancelled
                </div>
                <div v-else-if="isOtherCanceled" style="font-weight: bold">
                    CANCELED
                </div>
                <div v-else-if="time" :class="'time' + (isApproxTime ? ' approx' : '')">
                    <AlertBell v-if="!isDeparted && !tooSoonForAlert && pageType === 'rail'" :stop="stop" :trip="trip" :stationCode="stationCode" @set-alert-processing="setAlertProcessing" />
                    {{time}}
                </div>
                <div v-else>
                    <!-- placeholder -->
                </div>
                <!-- <div>
                    <div v-if="isDeparted" class="node departed-node">
                    </div>
                    <div v-else-if="$props.stop.isStart" class="node start-node">
                    </div>
                    <div v-else-if="$props.stop.isEnd" class="node end-node">
                    </div>
                    <div v-else class="node">
                    </div>
                </div> -->
            </div>
        </div>
    </div>

</template>

SCHEMA EXAMPLE:

Description: "HACKENSACK BUS TERMINAL"
ManLaneGate: ""
SchedDepTime: "7/21/2021 10:52:00 PM"
SchedLaneGate: ".."
TimePoint: "HACKTRAN"
TripNumber: "16893430"


<script>
    // import { Fragment } from 'vue-fragment';
    import { getTimeFromDatetime, insertPlane } from "@/Utilities";
    import AlertBell from "@/components/AlertBell";

    export default {
        name: "StopDisplay",
        props: ["stop", "query", "trip", "dataIndex", "stationCode"],
        components: {
            AlertBell
            // Fragment
        },
        data() {
            return {
                pageType: "",
                time: "",
                isApproxTime: false,
                isDeparted: false,
                isCancelled: false,
                tooSoonForAlert: false,
                isOtherCanceled: false,
                backColor: "",
                foreColor: "", 
                description: "",
                hasTransfers: false,
                nodeContainerClass: "node-container",
                isEnd: false
            }
        },
        created(){
            this.pageType = this.$route.path.split("/")[1];

            console.log("STOPS", this.$mq);
            // const dt = new Date(this.$props.stop.SchedDepTime);
            // const now = new Date();

            const status = this.$props.stop.Status;

            console.log("INDIVIDUAL STATUS", this.$props.stop, status);

            this.hasTransfers = this.$props.stop.transfers && this.$props.stop.transfers.length > 0;

            // if((this.pageType !== "rail" && now > dt) || status === "DEPARTED"){
            if(status === "DEPARTED" || status === "departed" || status === "Departed"){
                this.isDeparted = true;
            }

            if(this.$props.stop.isEnd){
                this.isEnd = true;
                this.isDeparted = false;
            }

            if(status === "CANCELLED" || status === "cancelled" || status === "Cancelled"){
                this.isCancelled = true;
            }

            if(status === "CANCELED" || status === "canceled" || status === "Canceled"){
                this.isOtherCanceled = true;
            }

            const timeToUse = this.$props.stop.SchedDepTime || this.$props.stop.ApproxTime;

            const now = Date.now();
            const sched = new Date(timeToUse);
            let diffMins = (sched - now)/60000;
            this.tooSoonForAlert = (diffMins < 5);

            this.isApproxTime = !(this.isDeparted || this.isCancelled) 
                                && (timeToUse !== this.$props.stop.SchedDepTime);

            this.time = getTimeFromDatetime(timeToUse);

            console.log("TRIP FOR STOP", this.$props.trip);

            if(this.$props.trip.BACKCOLOR){
                this.foreColor = this.$props.trip.FORECOLOR;
                this.backColor = this.$props.trip.BACKCOLOR;
            }
            else if(this.pageType === "lightrail" && !this.$props.query.fromNearby){
                this.foreColor = "black";
                if(this.$props.dataIndex % 2 === 0) {
                    this.backColor = "#d4b2f0";
                }
                else{
                    this.backColor = "#f4edfb";
                }
            }

            let containerClass = "node-container";

            if(this.$props.stop.isStart){
                containerClass += " start";
            }
            else if(this.$props.stop.isEnd) {
                containerClass += " end";
            }

            if(this.isDeparted){
                console.log("THIS SHOULD BE DEPARTED?")
                containerClass += " departed";
            }

            console.log("HAS TRANSFERS", this.$isTabletForm && this.hasTransfers);

            if(this.hasTransfers){
                containerClass += " has-transfers";
            }

            console.log("STILL TRANSFERS?", containerClass);

            this.nodeContainerClass = containerClass;

            this.description = insertPlane(this.$props.stop.Description);

            const hblr = this.description.indexOf("HBLR");
            if(hblr > -1) {
                this.description = this.description.substring(0, hblr);
            }
        },
        methods: {
            setAlertProcessing(value){
                console.log("STOP DISPLAY SET ALERT PROCESSING");
                this.$emit("set-alert-processing", value);
            }
        }
    }
</script>

<style scoped>
    .stop {
        width: 98%;
        margin: calc(0.9 * var(--mvh)) 0.8vw;
        padding: calc(1 * var(--mvh)) calc(1 * var(--mvh)) calc(1 * var(--mvh)) calc(2 * var(--mvh));
        border: 1px solid var(--primary-color);
        box-shadow: 0px 4px 2px -2px rgb(0 0 0 / 25%), 
                    0px 3px 3px 0px rgb(0 0 0 / 19%), 
                    0px 2px 5px 0px rgb(0 0 0 / 16%);
        border-radius: calc(1.5 * var(--mvh));
    }

    .mobile .stop,
    .tablet .stop {
        border-radius: 2vw;
        width: 97%;
        margin: calc(0.5 * var(--mvh)) 0vw !important;
    }

    .full-rotate .mobile .stop {
        padding-left: 1rem;
    }

    .stop * {
        font-size: 1.5rem;
    }

    .stop:nth-child(2n+1) {
        background-color: #e4edf9;
    }

    .stop:nth-child(2n+1):hover {
        /* background-color: #ddd; */
    }

    .mobile .stop * {
        font-size: 1.1rem;
    }

    .tablet .stop * {
        font-size: 1.25rem;
    }

    .mobile .stop .stop-last *:not(.time) {
        /* font-size: 0.75rem; */
        font-size: 1rem;
    }

    .stop:not(.forecolor) * {
        color: #222;
    }

    .landscape {
        display: grid;
        grid-template-columns: 2fr 2fr 2fr;
    }

    .landscape.no-transfer {
        grid-template-columns: 4fr 2fr;
    }

    .portrait {
        display: grid;
        grid-template-columns: 3fr 2fr;
    }

    .stop-first {
        display: grid;
        grid-template-columns: 1fr 7fr;
        /* grid-template-columns: 6fr; */
        justify-content: start;
        align-self: center;
    }

    .no-transfer .stop-first {
        grid-template-columns: 1fr 12fr;
    }

    /* .landscape .change-for {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    } */

    .change-for {
        text-align: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .mobile .change-for,
    .tablet .change-for {
        grid-column: 1 / -1;
    }

    .change-title {
        font-size: 0.88rem;
    }
    
    .stop-last {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 1rem;
    }

    .portrait .stop-last {
        grid-gap: 2vw;
        padding-right: 0;
        display: flex;
        align-items: center;
    }
    
    .node {
        border-radius: 50%;
        width: calc(4 * var(--mvh));
        height: calc(4 * var(--mvh));
        /* border: 4px solid white; */
        background-color: white;
        box-shadow: 1px 4px 2px -2px rgb(0 0 0 / 25%), 
                    1px 3px 3px 0px rgb(0 0 0 / 19%), 
                    0px 2px 7px 0px rgb(0 0 0 / 16%);
        position: relative;
        z-index: 1;
    }

    .node-container {
        position: relative;
    }

    .node-container.departed {
        color: black;
    }

    .node-container.departed:not(.start)::before,
    .node-container.departed::after {
        content: "";
        width: 0.4rem;
        height: calc(100% + 3 * var(--mvh) + 1.2 * var(--mvh));
        position: absolute;
        top: 5%;
        left: calc(2 * var(--mvh) + 0.25px);
        background-color: currentColor;
        /* border-left: 1px solid #eee;
        border-right: 1px solid #eee; */
        /* border-radius: 0.6rem;  */
        transform: translateX(-50%);
        box-shadow: 1px 2px 2px -2px rgb(0 0 0 / 25%), 
                    1px 3px 3px 0px rgb(0 0 0 / 19%), 
                    1px 7px 7px 0px rgb(0 0 0 / 16%);
        z-index: 0;
        /* background: linear-gradient(180deg, black, 10%, white 20%); */
    }

    .node-container.departed:not(.started)::before {
        top: -95%;
    }

    .rail .node-container.departed::after {
        height: calc(100% + 6 * var(--mvh) + 1.2 * var(--mvh));
    }

    .mobile .node-container.departed::before,
    .mobile .node-container.departed::after,
    .tablet .node-container.departed::before,
    .tablet .node-container.departed::after {
        /* width: 0.3rem; */
        width: 0.5rem;
        height: calc(100% + 6 * var(--mvh) + 0.5 * var(--mvh));
        /* left: calc(1.35 * var(--mvh)); */
        left: calc(1.8 * var(--mvh) + 1vw);
    }

    /* .mobile .rail .node-container::before {
        height: calc(100% + 10vh + 0.5vh);
    } */

    .mobile .node-container.has-transfers.departed::after,
    .tablet .node-container.has-transfers.departed::after {
        height: calc(100% + 4 * var(--mvh) + 1.2 * var(--mvh) + 2 * var(--mvh) + 36px);
    }

    /* .mobile .rail .node-container.has-transfers::before {
        height: calc(100% + 10vh + 1.2vh + 2vh + 36px);
    } */

    /* .node-container.departed::before {
        border: none;
        background-color: black;
        box-shadow: none;
    } */

    .start .node::before {
        background: linear-gradient(180deg, rgb(6, 214, 6), 5%, white 10%);
    }

    /* .node-container.start.departed::before {
        background: none;
        border: none;
        background-color: rgb(6, 214, 6);
    } */

    .node-container.end::before {
        height: 0 !important;
    }

    .node-mobile {
        /* width: calc(2.7 * var(--mvh));
        height: calc(2.7 * var(--mvh)); */
        width: calc(3.5 * var(--mvh));
        height: calc(3.5 * var(--mvh));
        margin-left: 1vw;
    }

    .departed .node {
        background-color: black;
        /* box-shadow: none; */
        box-shadow: 1px 4px 4px -2px rgb(0 0 0 / 25%), 
            1px 3px 5px 1px rgb(0 0 0 / 19%), 
            0px 2px 9px 1px rgb(0 0 0 / 16%);
    }

    .start .node {
        background-color: rgb(6, 214, 6);
        box-shadow: 1px 4px 4px -2px rgb(0 0 0 / 25%), 
                    1px 3px 5px 1px rgb(0 0 0 / 19%), 
                    0px 2px 9px 1px rgb(0 0 0 / 16%);
    }

    .end .node {
        background-color: rgb(216, 22, 22);
    }

    .name {
        font-weight: bold;
        /* font-size: 1.8rem; */
    }

    .mobile .name,
    .tablet .name {
        display: flex;
        align-items: center;
        padding-left: 1rem;
    }

    .spaced {
        margin: 0 1vw;
        display: flex;
        align-self: center;
    }

    .time {
        position: relative;
    }

    .portrait .time {
        font-size: inherit;
        margin-right: 0.2rem;
    }

    .time.approx {
        /* color: #666; */
        /* font-style: oblique; */
        position: relative;
        opacity: 0.85;
    }

    .time.approx::after {
        content: "estimated";
        position: absolute;
        font-size: 0.6rem;
        top: 90%;
        right: 0;
    }

    .mobile .stop {
        margin: calc(0.9 * var(--mvh)) calc(1.2 * var(--mvh));
        padding: calc(1.5 * var(--mvh)) calc(2 * var(--mvh)) calc(1.5 * var(--mvh)) calc(1 * var(--mvh));
    }

    .route-number {
        /* background-color: var(--primary-color); */
        border-radius: 50%;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;

        font-family: Roboto, sans-serif;
        font-weight: 500;
        /* width: 5vw;
        height: 5vw;
        font-size: 1.2rem; */
        padding: 0.2vw;
        aspect-ratio: 1;

        height: 36px;
        width: 36px;

        background-color: initial;
        border: none;
    }

    .mobile .rail-route,
    .tablet .rail-route {
        height: 36px;
        width: 36px;
        margin-top: calc(1.2 * var(--mvh));
        margin-bottom: calc(-0.7 * var(--mvh));
        border: none;
    }

    .tablet .rail-route {
        height: 50px;
        width: 50px;
    }

    /* @media only screen and (min-width: 601px) {
        .stop {
            margin: 1.5vh 1.2vh;
            padding: 1.5vh 2vh 1.5vh 1vh;
        }
    } */

</style>