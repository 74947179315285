import { render, staticRenderFns } from "./Expansion.vue?vue&type=template&id=098caa92&scoped=true&"
import script from "./Expansion.vue?vue&type=script&lang=js&"
export * from "./Expansion.vue?vue&type=script&lang=js&"
import style0 from "./Expansion.vue?vue&type=style&index=0&id=098caa92&prod&lang=css&"
import style1 from "./Expansion.vue?vue&type=style&index=1&id=098caa92&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "098caa92",
  null
  
)

export default component.exports