
<template>
    <div class="map-container scroll-check" :key="geolocationDenied">
        
        <NJT_Title v-if="$isTabletForm" singleLineOnly="true" :isMapPage="true" :lineDisplay="query.lineDisplay ? {station: query.station, tripid: query.tripid} : null" />

        <LaptopNavBar v-else-if="false" :condensed="true" :pageType="pageType" :query="query" :isMapPage="true" :lineDisplay="query.lineDisplay ? {station: query.station, tripid: query.tripid} : null" />

        <SimplifiedHeader
            v-else
            :logoClass="'njt-icon' + (($isMidTablet || $isBorderlineTablet) ? ' large-tablet-icon' : '')"
            @logo-click="redirectToLanding"
            :backButtonClass="!isTabletForm ? 'back-button-left-margin' : ''"
            :backButtonText="$isSmallScreen ? '' : 'HOME'"
            @back-button-click="navigateBackHome"
            :titleText="pageType + ' Departure Vision'"
            :titleTextClass="'banner-message capitalize'"
            :query="query"
            :pageType="pageType"
        >
        </SimplifiedHeader>

        <div :style="`width: 100vw; height: calc(${query.lineDisplay ? ($isTabletForm ? 84 : 91): 65} * var(--mvh)); display: flex; justify-content:flex-start; ${($isTabletForm) ? '' : ' position: absolute; top: calc(9 * var(--mvh));'}`">
            <MapDisplayTester
                :currentPosition="currentPosition" 
                :height="query.lineDisplay ? ($isTabletForm ? 'calc(84 * var(--mvh))' : 'calc(91 * var(--mvh))') : 'calc(65 * var(--mvh))'"
                :pageType="pageType" 
                :usingGeolocation="usingGeolocation" 
                :geolocationDenied="geolocationDenied"
                :fullPage="true"
                :lineDisplay="query.lineDisplay"
                :tripid="query.tripid"
            />
        </div>

        <NearbyContainer 
            v-if="!query.lineDisplay"
            :usingGeolocation="usingGeolocation" 
            :currentPosition="currentPosition"
            :isHorizontal="!$isTabletForm"
            @navigate-to-stops = "navigateToStops"
            @navigate-to-trips = "navigateToTrips"
        />
    </div>
</template>


<script>
    import { setQueryParameters, onlyNonNullValues, determineDefaultFontSize, encryptTrip, decryptObject, minPositionDifference } from "@/Utilities";
    
    import MapDisplayTester from "@/data_display/MapDisplayTester";

    import SimplifiedHeader from "@/components/SimplifiedHeader";

    import LaptopNavBar from "@/components/LaptopNavBar";
    import NJT_Title from "@/components/NJT_Title";
    import NearbyContainer from "@/components/NearbyContainer";

    import { executeKillSwitch } from "@/requests/BaseRequestValues";

    export default {
        name: "Map",
        components: {
            // MapDisplay
            MapDisplayTester,
            LaptopNavBar,
            NJT_Title,
            NearbyContainer,
            SimplifiedHeader
        },
        data(){
            return {
                query: {
                    layout: "",
                    fontSize: "",
                    scrolling: "",
                    route: "",
                    direction: "",
                    stop: "",
                    station: "",
                    status: "",
                    hidenav: null,
                    tripid: "",
                    late: null,
                    dark: null,
                    lineDisplay: null
                },
                usingGeolocation: false,
                geolocationDenied: false,
                currentPosition: null,
                pageType: "bus"
            }
        },
        watch: {
            query(newVal, oldVal) {
                console.log("DOES THE QUERY VALUE CHANGE?", newVal, oldVal);
            },
            geolocationDenied(newVal, oldVal) {
                console.log("GEO DENIED VALUE CHANGED?", newVal, oldVal);
            }
        },
        created() {
            setQueryParameters(this, true);
            this.appliedFontSize = determineDefaultFontSize(this.query, this.$mq);

            this.pageType = this.$route.path.split("/")[1];
            // this.setOverflow();

            if(navigator.permissions){
                navigator.permissions.query({name:'geolocation'})
                .then((permissionStatus) => {
                    console.log('geolocation permission state is ', permissionStatus.state);

                    this.processGeolocationState(permissionStatus);

                    permissionStatus.onchange = () => {
                        console.log('geolocation permission state has changed to ', this.state);
                        this.processGeolocationState(permissionStatus);
                    };
                })
                .catch(err => alert("permission query error: " + err.message));
            }
            else{
                this.watchPosition();
            }

            window.addEventListener("resize", this.resizeHandler);
        },
        mounted() {
            this.geolocate();
        },
        destroyed() {
            window.removeEventListener("resize", this.resizeHandler);
            console.log("DESTROY MAP");
            executeKillSwitch();
        },
        methods: {
            navigateBackToTrips(evt){
                evt.preventDefault();
                
                const nonTrip = {...this.$data.query};
                nonTrip.tripid = null;

                this.$router.push({
                    path: 'trips',
                    query: onlyNonNullValues(nonTrip) 
                });
            },
            navigateToTrips(stopValue, route, direction, stopName, modeVal){

                console.log("NAVIGATE TO TRIPS FORM MAP", stopValue, stopName);

                if(this.pageType === "rail"){
                    this.query.station = stopValue;
                }
                else{
                    this.query.stop = stopValue;
                    this.query.stopName = stopName;
                }

                if(modeVal === "NLR" || modeVal === "RL" || modeVal === "HBLR"){
                    this.query.route = modeVal;
                }

                this.query.fromMap = true;

                console.log("NAVIGATE", this.query);

                this.$router.push({
                    path: `/${this.pageType}/trips`, 
                    query: onlyNonNullValues(this.$data.query)
                });
            },
            navigateToStops(trip) {
                console.log("MEMBER HOME NAVIGATE", trip);

                let valueToUse = null;
                let vehicleID = null;

                if(this.pageType === "rail"){
                    valueToUse = trip.VehicleID;
                    this.$data.query.mode = "RAIL";
                    vehicleID = trip.VehicleID;
                }
                else {
                    valueToUse = encryptTrip(trip);

                    console.log("ENCRYPTED VAUE TO USE FOR TRIP ID", trip, valueToUse);

                    const o = decryptObject(valueToUse);

                    console.log("DECRYPTED TRIP IMMEDIATELY", o);

                    vehicleID = trip.VehicleID;
                }

                console.log("NAVIGATE PATH", `/${this.pageType}/stops`);

                this.query.fromMap = true;
                this.query.vid = vehicleID;

                console.log("HAVE VEHICLE ID", trip, vehicleID, this.query.vid);

                this.$router.push({
                    path: `/${this.pageType}/stops`, 
                    query: onlyNonNullValues({...this.$data.query, tripid: valueToUse})
                });
            },
            navigateBackHome(){
                this.$router.push({
                    path: '/'
                });
            },
            geolocate: function() {
                console.log("GEOLOCATING");
                navigator.geolocation.getCurrentPosition(position => {
                    console.log("WE GOT A GEO POSITION", position);

                    if(position){
                        this.usingGeolocation = true;
                        this.currentPosition = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        };
                    }
                    else{
                        setTimeout(this.geolocate, 50);
                    }
                }, (err) => {
                    console.log("GEO LOCATING GET CURRENT ERROR", err);
                    if(err.code === 3){
                        this.geolocate();
                    }
                }, 
                {timeout: 1000, enableHighAccuracy: false, maximumAge:Infinity}
                );
            },
            watchPosition() {
                console.log("WATCHNG THE GEO POSITION");
                this.geolocate();

                const permissionDenied = () => {
                    // alert("MADE IT HERE 1");
                    this.usingGeolocation = false;
                    this.geolocationDenied = true;
                    // alert("MADE IT HERE 2");
                }

                navigator.geolocation.watchPosition((position) => {
                    console.log("WATCHING FOR NEW GEO POSITION", position);
                    this.usingGeolocation = true;
                    this.geolocationDenied = false;

                    let proceed = !this.currentPosition;
                    proceed |= minPositionDifference(position, this.currentPosition);

                    if(proceed){
                        this.currentPosition = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        };
                    }
                },
                function(error) {
                    if (error.code == error.PERMISSION_DENIED){
                        // alert("GEOLOCATION IS DENIED 1");
                        permissionDenied();
                    }
                });
            },
            processGeolocationState(permissionStatus) {
                console.log("GEOLOCATION PERMISSION?", permissionStatus);
                switch(permissionStatus.state) {
                    case "granted":
                        console.log("GEOLOCATION PERMISSION GRANTED");
                        this.usingGeolocation = true;
                        this.geolocationDenied = false;

                        console.log("GEO SET, DO WE START WATCHING?");

                        this.watchPosition();
                        
                        break;
                    case "denied":
                        this.usingGeolocation = false;
                        this.geolocationDenied = true;
                        // alert("GEOLOCATION IS DENIED 2");
                        break;
                    case "prompt":
                        this.watchPosition();
                        break;
                    default:
                        // treat as denied
                        this.usingGeolocation = false;
                        this.geolocationDenied = true;
                        // alert("GEOLOCATION IS DENIED 3");
                }
            }
        }
    }
</script>

<style>
    .back-button-left-margin {
        margin-left: 1rem;
    }
</style>