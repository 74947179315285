<template>
    <div :class="'header ' + fullClass" :style="style">
        <div class="inner-container">
            <div style="display:flex; align-items: center;">
                <div v-if="showLogo" :class="'logo-container ' + logoClass" @click="logoClick" :style="logoStyle">
                </div>
                <SimplifiedButton
                    v-if="backButtonText"
                    :buttonClass="'back-button-container ' + backButtonClass"
                    @click-event="backButtonClick"
                >
                    {{ backButtonText }}
                </SimplifiedButton>
            </div>

            <div :class="'title-container ' + titleClass">

                <div v-if="(iconClass || iconStyle) && !backIcon" :class="'icon-container ' + iconClass" :style="iconStyle">
                    {{ busRoute }}
                </div>
                <div v-if="(iconClass || iconStyle) && !backIcon && iconFollowUp">
                    {{ iconFollowUp }}
                </div>
                <div :class="'title-text ' + titleTextClass">
                    <div class="title-spacer"></div>
                    {{ titleText }}
                </div>
                <div v-if="postSpace" class="post-space"></div>
                <div v-if="showMapIcon" :class="'map-icon-container ' + mapIconClass" @click="mapButtonClick" :style="mapButtonStyle">
                </div>
                <div v-if="(iconClass || iconStyle) && backIcon && busRoute" :class="'icon-container ' + (showMapIcon ? mapIconClass : iconClass)" :style="showMapIcon ? mapButtonStyle : iconStyle">
                    {{ busRoute }}
                </div>
                <div v-if="(iconClass || iconStyle) && backIcon && iconFollowUp">
                    {{ iconFollowUp }}
                </div>
            </div>
        </div>

        <div v-if="!navButtonHide && !$isSignMode" :class="'nav-button-container ' + navClass">
            <NavigationButtons :pageType="pageType" :query="query" :condensed="true" />
        </div>
    </div>
</template>

<script>

    import SimplifiedButton from './SimplifiedButton.vue';
    import NavigationButtons from './NavigationButtons.vue';

    export default {
        name: "SimplifiedHeader",
        props: ["headerClass", "showLogo", "logoClass", "titleClass", "backButtonClass"
                , "iconClass", "mapIconClass", "titleText", "titleTextClass"
                , "backButtonText", "iconSource", "showMapIcon", "navClass"
                , "logoStyle", "iconStyle", "mapButtonStyle", "navButtonHide"
                , "query", "pageType", "backIcon", "iconFollowUp", "busRoute", "postSpace"],
        components: {
            SimplifiedButton,
            NavigationButtons
        },
        watch: {
            headerClass() {this.buildStyle();},
            showLogo() {this.buildStyle();},
            logoClass() {this.buildStyle();},
            titleClass() {this.buildStyle();},
            backButtonClass() {this.buildStyle();},
            iconClass() {this.buildStyle();},
            mapIconClass() {this.buildStyle();},
            titleText() {this.buildStyle();},
            titleTextClass() {this.buildStyle();},
            backButtonText() {this.buildStyle();},
            iconSource() {this.buildStyle();},
            showMapIcon() {this.buildStyle();},
            navClass() {this.buildStyle();},
            logoStyle() {this.buildStyle();},
            iconStyle() {this.buildStyle();},
            query() {this.buildStyle();},
            pageType() {this.buildStyle();},
            backIcon() {this.buildStyle();},
            iconFollowUp() {this.buildStyle();},
            busRoute() {this.buildStyle();}
        },
        data() {
            return {
                style: "",
                fullClass: ""
            }
        },
        created(){
            this.buildStyle();
            console.log("HEADER PROPS", this.$props);
        },
        methods: {
            logoClick(evt) {
                this.$emit("logo-click", evt);
            },
            backButtonClick(evt) {
                console.log("BACK BUTTON CLICK SIMPLIFIED");
                this.$emit("back-button-click", evt);
            },
            mapButtonClick(evt) {
                this.$emit("map-button-click", evt);
            },
            buildStyle(){
                this.fullClass = this.$props.headerClass;
            }
        }
    }
</script>

<style scoped>

    .header {
        /* height: 100%;  */
        width: 100%;
        display: grid;
        grid-template-columns: 60vw 40vw;
        
        background: var(--primary-color);
        color: white;
        border-bottom: none;

        position: fixed;
        height: calc(9* var(--mvh));
    }

    .mobile .header,
    .smallscreen .header {
        position: relative;
        /* padding: 0.5rem 1rem; */
        box-shadow: 0 0 10px var(--primary-color);
        display: initial;
    }

    .inner-container {
        display: flex;
        flex-direction: row;
        height: 100%;
        align-items: center;
        justify-content: flex-start;
    }

    .mobile .inner-container,
    .smallscreen .inner-container {
        display: flex;
        /* grid-template-columns: 20vw 80vw; */
        align-items: center;
        justify-content: center;

        background-color: var(--primary-color);
        color: white;
        height: calc(10* var(--mvh));
    }

    .tablet .inner-container {
        grid-template-columns: 15vw 85vw;
    }

    .smallscreen .njt-icon {
        margin-left: 1rem;
        width: calc(var(--mvh)* 5.3);
        height: calc(var(--mvh)* 6.6);
    }

    .mobile .nav-button-container,
    .smallscreen .nav-button-container {
        display: none;
    }

    .title-container {
        display: flex;
        align-items: center;
        padding-left: 1.5rem;
    }

    .smallscreen .title-container {
        /* width: 75vw; */
        padding-left: 1rem;
        flex-grow: 1;
    }

    .mobile .title-container {
        /* justify-content: center; */
        padding-left: 0;
    }

    .title-text {
        /* font-size: 2rem; */
        font-size: clamp(1rem, 2vw, 2rem);
        padding: 0 1rem;
        display: flex;
        align-items: center;
        text-transform: capitalize;
    }

    .mobile .title-text,
    .smallscreen .title-text {
        /* max-width: 75vw; */
        font-size: 1.35rem;
        font-weight: 600;
    }

    .tablet .title-text {
        justify-content: center;
    }

    .logo-container {
        width: 3.6rem;
        height: 4.5rem;
        margin-left: 1.2rem;
        margin-right: 2.5rem;
        /* transform: translateY(.14rem) scale(.7); */
    }

    .logo-container::after {
        content: "";
        position: absolute;
        right: -1.3rem;
        width: 0.2rem;
        height: 100%;
        top: 50%;
        transform: translateY(-50%);
        background-color: white;
    }

    .smallscreen .location-banner::after {
        content: "";
        position: absolute;
        background-color: #fff;
        height: 100%;
        width: 2px;
        top: 0;
        right: -1rem;
    }

    .mobile .logo-container,
    .tablet .logo-container,
    .smallscreen .logo-container {
        transform: none;
        margin-right: 2rem;
    }

    .mobile .logo-container::after,
    .smallscreen .logo-container::after {
        /* display: none; */
    }

    .back-button-container .button {
        height: 2rem;
    }

    .smallscreen .back-button-container,
    .tablet .back-button-container {
        margin-right: 0;
        margin-left: 0.5rem;
    }

    .mobile .back-button-container {
        margin-right: 0;
        margin-left: 1.5rem;
    }

    .capitalize {
        text-transform: capitalize;
    }

    .njt-icon {
        position: relative;
        content: "";
        /* background: url("../assets/njt_logo_white.svg"); */
        background: url("../assets/hug-icon-s-white.svg");
        background-repeat: no-repeat;
        background-size: cover;
        margin-left: 1rem;
        /* width: 2rem;
        height: 2.5rem; */
        width: 2.5rem;
        height: 3.15rem;
        cursor: pointer;
    }

    .mobile .njt-icon {
        /* width: 2.9rem;
        height: 3.6rem; */
    }

    .back-button-container {
        /* position: absolute; */
        /* left: 6rem; */
        margin-right: 1rem;
    }

    .title-spacer {
        display: none;
    }

    .extra-spacer .title-spacer {
        display: block;
        width: 2rem;
    }

    .centered {
        text-align: center;
    }

    .post-space {
        width: 12vw;
        height: 1rem;
    }

    .tablet .post-space {
        width: 16vw;
    }

    .smallscreen-centered-title {
        justify-content: center;
        text-align: center;
    }

</style>